import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import { CircularProgress } from "@material-ui/core";
import {
  getInternshipCertificate,
  getInternshipShortCertificate,
} from "../../services/dataService";
import QRCode from "qrcode";
import { BASE_URL } from "../../services/configUrls";
import certificates from "../../assets/apsche.png";

const Certificate = () => {
  const [certificateData, setCertificateData] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const certificateRef = useRef(null);

  useEffect(() => {
    getInternshipShortCertificate
      .getShortTermCertficate()
      .then((response) => {
        setCertificateData(response.data);
        const qrCodeURL = `${BASE_URL}${response.data.verification_link}`;
        QRCode.toDataURL(qrCodeURL)
          .then(setQrCodeUrl)
          .catch((error) => {
            console.error("Error generating QR code:", error);
          });
      })
      .catch((error) => {
        return (
          <Box
            sx={{
              display: "flex",
              color: "red",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Full height to center vertically
            }}
          >
            <Typography>An Error Occurred</Typography>
          </Box>
        );
      });
  }, []);

  const handleDownloadPDF = () => {
    const certificateElement = certificateRef.current;

    const scale = 4; // High scale for maximum resolution (4x or higher)
    const width = certificateElement.clientWidth * scale;
    const height = certificateElement.clientHeight * scale;

    const options = {
      width,
      height,
      style: {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
      },
      quality: 1, // Maximum quality
      bgcolor: "#ffffff", // Ensure the background is white
    };

    domtoimage
      .toPng(certificateElement, options)
      .then((imgData) => {
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [
            certificateElement.clientWidth,
            certificateElement.clientHeight,
          ],
        });

        pdf.addImage(
          imgData,
          "PNG",
          0,
          0,
          certificateElement.clientWidth,
          certificateElement.clientHeight,
          undefined,
          "FAST" // Use 'FAST' to reduce aliasing issues
        );
        pdf.save(`${certificateData.name}_certificate.pdf`);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  if (!certificateData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full height to center vertically
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const UnderlinedText = ({ children }) => (
    <Typography
      component="span"
      sx={{
        borderBottom: "2px solid black",
        paddingBottom: "2px",
        fontWeight: "bold",
        fontFamily: "Times New Roman",
        color: "black !important",
        fontSize: "1.35rem", // Times New Roman with fallback
      }}
    >
      {children}
    </Typography>
  );

  const BoldText = ({ children }) => (
    <Typography
      component="span"
      sx={{
        fontWeight: "bold",
        fontFamily: "Times New Roman",
        fontSize: "1.35rem",
        color: "black !important",
      }}
    >
      {children}
    </Typography>
  );

  const SemiBoldText = ({ children }) => (
    <Typography
      component="span"
      sx={{
        fontWeight: "500",
        fontFamily: "Times New Roman",
        fontSize: "1.35rem",
        color: "black !important",
      }}
    >
      {children}
    </Typography>
  );
  const SemiBoldUnderlineText = ({ children }) => (
    <Typography
      component="span"
      sx={{
        borderBottom: "2px solid black",
        paddingBottom: "2px",
        fontWeight: "500",
        fontFamily: "Times New Roman",
        color: "black !important",
        fontSize: "1.35rem",
      }}
    >
      {children}
    </Typography>
  );
  const GrayishText = ({ children }) => (
    <Typography
      component="span"
      sx={{
        // borderBottom: "2px solid black",
        // paddingBottom: "2px",
        //fontWeight: "500",
        fontFamily: "Times New Roman",
        color: "#666666 !important",
        fontSize: "1.35rem",
      }}
    >
      {children}
    </Typography>
  );

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  if (!certificateData || isEmptyObject(certificateData)) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No Certificate Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ fontFamily: "Times New Roman !important" }}>
      <Box
        sx={{
          overflowX: "auto", // Enable horizontal scrolling
          maxWidth: "100%", // Ensure it doesn't exceed the screen width
        }}
      >
        <Box
          ref={certificateRef}
          sx={{
            position: "relative",
            width: "1120px", // Fixed width for the certificate
            height: "auto",
            margin: "auto",
          }}
        >
          <img
            src={certificates}
            alt="Certificate"
            style={{ width: "100%", height: "auto" }}
          />

          <Typography
            variant="body1"
            sx={{
              position: "absolute",
              top: "44%",
              left: "10%",
              right: "10%",
              color: "#666666", // Light gray color for the main text
              fontSize: "1.35rem",
              lineHeight: "1.9",
              textAlign: "justify", // Justify text
              fontFamily: "Times New Roman !important",
              textJustify: "inter-word", // Ensure words are spaced evenly
            }}
          >
            This is to certify that Ms./Mr.{" "}
            <UnderlinedText>{certificateData.name}</UnderlinedText>
            <SemiBoldUnderlineText>
              , <GrayishText>Course :</GrayishText> {certificateData.course}
              {/* </SemiBoldUnderlineText> */},{" "}
              <GrayishText>Branch : </GrayishText>
              {/* <SemiBoldUnderlineText> */}
              {certificateData.branch}
              {/* </SemiBoldUnderlineText> */},{" "}
              <GrayishText>Semester : </GrayishText>
              {/* <SemiBoldUnderlineText> */}
              {certificateData.semester}, {/* </SemiBoldUnderlineText> */}
              <GrayishText>Roll No : </GrayishText>
              {/* <SemiBoldUnderlineText> */}
              {certificateData.roll_no} <GrayishText>Under</GrayishText>{" "}
              {/* <UnderlinedText> */}
              <UnderlinedText>{certificateData.institute_name}</UnderlinedText>
              {/* </UnderlinedText>{" "} */} <GrayishText>of</GrayishText>{" "}
              {/* <UnderlinedText> */}
              <UnderlinedText>{certificateData.university_name}</UnderlinedText>
              {/* </UnderlinedText>{" "} */}
            </SemiBoldUnderlineText>{" "}
            has Successfully completed the{" "}
            <SemiBoldText> Short-Term Internship for 120 Hours</SemiBoldText> on{" "}
            <SemiBoldUnderlineText>
              {certificateData.domains}
            </SemiBoldUnderlineText>{" "}
            Organised by <BoldText>EduSkills</BoldText> in Collaboration with{" "}
            <BoldText>
              Andhra Pradesh State Council of Higher Education
            </BoldText>
            .
          </Typography>

          <Box
            sx={{
              position: "absolute",
              bottom: "8%",
              left: "10%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: "500",
                color: "#666666 !important",
                fontFamily: "Times New Roman",
                marginBottom: "10px",
              }}
            >
              Certificate No. : {certificateData.cert_no}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.1rem",
                fontWeight: "500",
                color: "#666666 !important",
                fontFamily: "Times New Roman",
              }}
            >
              Issue Date : {certificateData.cert_gen_date}
            </Typography>
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "5%",
              right: "0",
              left: 0,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {qrCodeUrl && (
              <img
                src={qrCodeUrl}
                alt="QR Code"
                style={{ width: "120px", height: "120px" }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        <Button
          variant="contained"
          onClick={handleDownloadPDF}
          sx={{
            background: "linear-gradient(to right, #2193b0, #6dd5ed)",
            color: "white",
            fontWeight: "bold",
            padding: "10px 20px",
            textTransform: "uppercase",
            fontSize: "1rem",
            "&:hover": {
              background: "linear-gradient(to right, #6dd5ed, #2193b0)",
            },
          }}
        >
          Download Certificate
        </Button>
      </Box>
    </Box>
  );
};

export default Certificate;
