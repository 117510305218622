import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import nojob from "../../assets/imgs/svg/nojob.svg";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import TablePagination from "@mui/material/TablePagination";
import { Helmet } from "react-helmet-async";
import { Oval } from "react-loader-spinner";
import { appliedJobService } from "../../services/dataService";

const MyJobs = () => {
  const [value, setValue] = useState("1");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState("");
  const [jobList, setJobList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleApplyJob = () => {
    navigate("/jobs/opening");
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleRemarkView = (job) => {
    if (job.remark) {
      setSelectedRemark(job.remark);
      setOpenModal(true);
    }
  };
  const handleView = (jobId) => {
    // console.log(jobId, "job id here");
    navigate("/jobs/applied/view", { state: { jobId } });
  };

  //   } else {
  //     navigate("/jobs/applied/view", { state: { jobId: job.job_id } });
  //   }
  // };
  //..........fetch applied jobs
  useEffect(() => {
    const fetchAppliedJobsData = async () => {
      try {
        setLoading(true);
        const response = await appliedJobService.appliedJobData();
        // console.log(response?.data?.applied_jobs, "wtf no data 🧑🏻‍🏭");
        setJobList(response?.data?.applied_jobs);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
        // console.log("there is something error", error);
      }
    };
    fetchAppliedJobsData();
  }, []);
  // Filter jobs based on status
  // console.log(jobList);
  const activeJobs = jobList?.filter((job) => job.status === "Active");
  // console.log(activeJobs);
  const inactiveJobs = jobList?.filter((job) => job.status === "Inactive");
  // console.log(inactiveJobs);
  // Paginate data based on current page and rowsPerPage
  const paginatedData = (data) => {
    return data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };
  const totalCount = value === "1" ? activeJobs?.length : inactiveJobs?.length;

  return (
    <div className="m-5 shadow-md rounded-md min-h-96">
      <Helmet>
        <title> EduSkills | Applied Jobs </title>
      </Helmet>
      <h2 className="text-lg font-semibold p-6">My Applications</h2>
      <div>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Active" value="1" />
              <Tab label="Inactive" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {loading ? (
              <div className="flex items-center justify-center h-screen">
                <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : activeJobs?.length > 0 ? (
              <div className="overflow-x-auto  scrollbar-thin">
                <table className="min-w-full shadow-sm border-gray-300 mt-8 text-xs md:text-base">
                  <thead>
                    <tr className="md:text-base text-xs text-left">
                      <th className="py-2 px-4 border-b">Sno</th>
                      <th className="py-2 px-4 border-b">Job Title</th>
                      <th className="py-2 border-b">Company Name</th>
                      <th className="py-2  border-b">Job Status</th>
                      <th className="py-2  border-b">Date Submitted</th>
                      <th className="py-2  border-b">Remark</th>
                      <th className="py-2 px-4 border-b">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData(activeJobs)?.map((job, index) => (
                      <tr key={index}>
                        <td className="py-2 border-b px-4">{index + 1}</td>
                        <td className="py-2 px-4 border-b">{job.job_title}</td>
                        <td className="py-2 px-6 border-b">{job.company}</td>
                        <td className="py-2 px-4 border-b">
                          <Chip
                            color={
                              job?.jobStatus === "inProgress"
                                ? "warning"
                                : "secondary"
                            }
                            variant="outlined"
                            size="small"
                            label={job?.status}
                          />
                        </td>
                        <td className="py-2 px-4 border-b">
                          {job?.dateSubmitted}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {job.remark ? (
                            <button onClick={() => handleRemarkView(job)}>
                              <Tooltip title="View">
                                <Icon
                                  icon="mdi:eye-outline"
                                  height={22}
                                  width={22}
                                />
                              </Tooltip>
                            </button>
                          ) : (
                            <Icon icon="bi:dash" height={22} width={22} />
                          )}
                        </td>
                        <td className="py-2 border-b px-6">
                          <button onClick={() => handleView(job?.job_id)}>
                            <Tooltip title="job details">
                              <Icon
                                icon="ph:dots-three"
                                height={22}
                                width={22}
                              />
                            </Tooltip>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className=" overflow-auto">
                  <TablePagination
                    component="div"
                    count={totalCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 15]}
                    labelDisplayedRows={({ from, to, count }) => (
                      <Typography
                        style={{ fontSize: "0.9", fontWeight: "bold" }}
                        component={"span"}
                        variant={"body2"}
                      >
                        {from}-{to} of {count}
                      </Typography>
                    )}
                    labelRowsPerPage={
                      <Typography
                        style={{ fontSize: "0.9rem" }}
                        component={"span"}
                        variant={"body2"}
                      >
                        Rows per page
                      </Typography>
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <img src={nojob} alt="No job found" />
                <p className="text-base text-slate-600">
                  You have no active applications.
                </p>
                <button
                  className="px-4 py-1 bg-slate-300 rounded-md mt-5 text-base"
                  onClick={handleApplyJob}
                >
                  Search for Jobs
                </button>
              </div>
            )}
          </TabPanel>
          <TabPanel value="2">
            {inactiveJobs?.length > 0 ? (
              <div className=" overflow-x-auto scrollbar-thin">
                <table className="min-w-full overflow-x-auto shadow-sm border-gray-300 mt-8 text-xs md:text-base">
                  <thead>
                    <tr className=" md:text-base text-xs text-left">
                      <th className="py-2 px-4 border-b">Sno</th>
                      <th className="py-2 px-4 border-b">Job Title</th>
                      <th className="py-2 border-b">Company Name</th>
                      <th className="py-2  border-b">Job Status</th>
                      <th className="py-2  border-b">Date Submitted</th>
                      <th className="py-2  border-b">Remark</th>
                      <th className="py-2 px-4 border-b">View</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {paginatedData(inactiveJobs)?.map((job, index) => (
                      <tr key={index}>
                        <td className="py-2 border-b px-4">{index + 1}</td>
                        <td className="py-2 px-4 border-b">{job?.job_title}</td>
                        <td className="py-2 px-6 border-b">{job?.company}</td>
                        <td className="py-2 px-4 border-b">
                          <Chip
                            color="error"
                            variant="outlined"
                            label={job?.status}
                            size="small"
                          />
                        </td>
                        <td className="py-2 px-4 border-b">
                          {job?.dateSubmitted}
                        </td>
                        <td className="py-2 px-4 border-b">
                          {" "}
                          {job?.remark ? (
                            <button onClick={() => handleRemarkView(job)}>
                              <Tooltip title="view">
                                <Icon
                                  icon="mdi:eye-outline"
                                  height={22}
                                  width={22}
                                />
                              </Tooltip>
                            </button>
                          ) : (
                            <Icon icon="bi:dash" height={22} width={22} />
                          )}
                        </td>
                        <td className="py-2 border-b px-6">
                          <button onClick={() => handleView(job?.job_id)}>
                            <Tooltip title="job details">
                              <Icon
                                icon="ph:dots-three-bold"
                                height={22}
                                width={22}
                              />
                            </Tooltip>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15]}
                  labelDisplayedRows={({ from, to, count }) => (
                    <Typography
                      style={{ fontSize: "0.9", fontWeight: "bold" }}
                      component={"span"}
                      variant={"body2"}
                    >
                      {from}-{to} of {count}
                    </Typography>
                  )}
                  labelRowsPerPage={
                    <Typography
                      style={{ fontSize: "0.9rem" }}
                      component={"span"}
                      variant={"body2"}
                    >
                      Rows per page
                    </Typography>
                  }
                />
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <img src={nojob} alt="No job found" />
                <p className="text-base text-slate-600">
                  You have no inactive applications.
                </p>
                <button
                  className="px-4 py-1 bg-slate-300 rounded-md mt-5 text-base"
                  onClick={handleApplyJob}
                >
                  Search for Jobs
                </button>
              </div>
            )}
          </TabPanel>
        </TabContext>
      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Remark</DialogTitle>
        <DialogContent>
          <Typography>{selectedRemark}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyJobs;
