import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import CloseIcon from "@mui/icons-material/Close";
import { EditIcon } from "lucide-react";

// Styled components
const ProfileImageContainer = styled(Box)(({ theme }) => ({
  width: 150,
  height: 150,
  position: "relative",
  margin: "0 auto",
}));

const ProfileImage = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  overflow: "hidden",
  border: `2px solid ${theme.palette.grey[300]}`,
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

// Modified ImageOverlay with hover effect
const ImageOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: "50%",
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  opacity: 0,
  transition: "opacity 0.2s ease-in-out",
  "&:hover": {
    opacity: 1,
  },
}));

const CameraButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  transition: "background-color 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  padding: theme.spacing(1.5),
}));

const Input = styled("input")({
  display: "none",
});

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "20px !important",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .ReactCrop": {
    maxHeight: "70vh",
    maxWidth: "100%",
    "& img": {
      maxHeight: "70vh",
      maxWidth: "100%",
      objectFit: "contain",
    },
  },
}));

const ProfileForm = () => {
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [openCropDialog, setOpenCropDialog] = useState(false);
  const [finalImage, setFinalImage] = useState(null);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const imgRef = useRef(null);
  const fileInputRef = useRef(null);

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        const img = new Image();
        img.src = reader.result?.toString() || "";
        img.onload = () => {
          setImageDimensions({
            width: img.width,
            height: img.height,
          });
          setOpenCropDialog(true);
        };
      });
      reader.readAsDataURL(e.target.files[0]);

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  const handleCancelPhoto = () => {
    setFinalImage(null);
    setImgSrc("");
    setCrop(undefined);
    setCompletedCrop(null);
  };

  async function createCroppedImage(image, crop) {
    if (!image || !crop) return;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.floor(crop.width * scaleX);
    canvas.height = Math.floor(crop.height * scaleY);

    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );

      const base64Image = canvas.toDataURL("image/jpeg");
      setFinalImage(base64Image);
      setOpenCropDialog(false);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      profileImage: finalImage,
    };
    console.log("Form submitted:", data);
  };

  const getDialogDimensions = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const padding = 48;

    const maxWidth = Math.min(
      imageDimensions.width + padding,
      screenWidth * 0.9
    );
    const maxHeight = Math.min(
      imageDimensions.height + padding,
      screenHeight * 0.9
    );

    return {
      maxWidth: `${maxWidth}px`,
      width: "auto",
    };
  };

  return (
    // <Paper elevation={3} sx={{ maxWidth: 400, mx: "auto", p: 4, marginTop: 2 }}>
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          Update Photo
        </Typography>

        {/* Profile Image Section with Hover Overlay */}
        <ProfileImageContainer>
          <ProfileImage>
            <img
              src={finalImage || "https://placehold.co/800?text=Click+Here&font=roboto"}
              alt="Profile"
            />
          </ProfileImage>

          {/* Hover Overlay with Camera Button */}
          <ImageOverlay>
            <label htmlFor="icon-button-file">
              <Input
                ref={fileInputRef}
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={onSelectFile}
              />
              <CameraButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <PhotoCamera sx={{ fontSize: 28 }} />
              </CameraButton>
            </label>
          </ImageOverlay>
        </ProfileImageContainer>

        {/* Cancel Button - Only shown when there's an image */}
        {finalImage && (
          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCancelPhoto}
              startIcon={<CloseIcon />}
            >
              Remove Photo
            </Button>
          </Box>
        )}

        {/* Update Profile Button */}
        <Tooltip title={!finalImage ? "Please upload a photo first" : ""}>
          <span>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              disabled={!finalImage}
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: (theme) =>
                    theme.palette.action.disabledBackground,
                  color: (theme) => theme.palette.action.disabled,
                },
              }}
            >
              Update Photo
            </Button>
          </span>
        </Tooltip>
      </Box>

      {/* Crop Dialog */}
      <Dialog
        open={openCropDialog}
        onClose={() => setOpenCropDialog(false)}
        PaperProps={{
          sx: {
            ...getDialogDimensions(),
            margin: "20px",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Crop Image</Typography>
          <IconButton onClick={() => setOpenCropDialog(false)} sx={{ p: 1 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <StyledDialogContent dividers>
          {imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1}
              circularCrop
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </StyledDialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button onClick={() => setOpenCropDialog(false)}>Cancel</Button>
          <Button
            onClick={() => createCroppedImage(imgRef.current, completedCrop)}
            variant="contained"
            disabled={!completedCrop}
          >
            Crop & Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
    // </Paper>
  );
};

export default ProfileForm;
