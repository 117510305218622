import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { tokens } from "../../theme";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import CustomError from "../../components/Error/CustomError";
import { applyingJobService } from "../../services/dataService";
const columns = [
  { id: "sl_no", label: "Sl", minWidth: 20 },
  { id: "job_title", label: "Job Title", minWidth: 150 },
  { id: "company", label: "company Name", minWidth: 100 },
  { id: "job_role", label: "job Role", minWidth: 150 },
  { id: "no_of_post", label: "No.posts", minWidth: 20 },
  { id: "reg_start", label: "PublishDate", minWidth: 20 },
  { id: "reg_end", label: "last Date", minWidth: 30 },
  { id: "action", label: "Action", minWidth: 30 },
];
const createData = (
  sl_no,
  job_title,
  company,
  job_role,
  job_id,
  no_of_post,
  reg_start,
  reg_end
) => {
  return {
    sl_no,
    job_title,
    company,
    job_role,
    job_id,
    no_of_post,
    reg_start,
    reg_end,
    action: "View",
  };
};

const OpeningJobs = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [jobsData, setJobsData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  // const [selectedRowId, setSelectedRowId] = useState(null);
  const navigate = useNavigate();
  //....................
  useEffect(() => {
    // Simulating data fetching delay
    const fetchData = async () => {
      try {
        // Simulating API call or data fetching
        setLoading(true);
        const response = await applyingJobService.jobApply();
        console.log(response?.data);
        const apiJobsData = response.data;
        const formattedJobsData = apiJobsData.data.map((job) =>
          createData(
            job.sl_no,
            job.job_title,
            job.company,
            job.job_role,
            job.job_id,
            job.no_of_post,
            job.reg_start,
            job.reg_end
          )
        );
        //api call ending
        setLoading(false);
        // await new Promise((resolve) => setTimeout(resolve, 1500));
        // console.log(formattedJobsData?.length, "here is length");
        setJobsData(formattedJobsData);
        setFilteredData(formattedJobsData);
        // setJobsData(jobsData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    // This effect will be triggered whenever searchQuery changes
    const debounceTimer = setTimeout(() => {
      // Perform filtering logic after 3 seconds of inactivity
      const filtered = jobsData?.filter((row) => {
        return (
          row.job_title.toLowerCase().includes(searchQuery) ||
          row.company.toLowerCase().includes(searchQuery) ||
          row.job_role.toLowerCase().includes(searchQuery)
        );
      });
      setFilteredData(filtered || []);
      // setJobsData(filteredData);
      setPage(0);
    }, 3000);

    // Cleanup function to clear the timer on each searchQuery change
    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };
  const handleApply = (rowData) => {
    // setSelectedRowId(rowData.id);
    navigate("/jobs/details", { state: { rowData } });
  };
  const filtereData = jobsData?.filter((row) => {
    const searchTerms = Object.values(row).join(" ").toLowerCase();
    return searchTerms.includes(searchQuery.toLowerCase());
  });

  const paginationLabelStyle = {
    fontSize: "1.6rem",
    fontWeight: "bold",
  };
  return (
    <div className="m-5">
      <Helmet>
        <title> EduSkills | Jobs opening </title>
      </Helmet>
      <Paper sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
        {loading ? (
          <Skeleton
            animation="wave"
            height={70}
            width={200}
            className=" m-2 ml-5"
          />
        ) : (
          //      error ? (
          //        <CustomError message={error} />
          //       ) :
          <div className="m-2 relative">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="p-2 pl-8 rounded-md w-full md:w-60 shadow-sm outline-sky-300 border-2 border-gray-300 text-gray-900"
            />
            <div className="absolute top-0 left-0 m-2">
              <Icon
                icon="material-symbols:search"
                height={24}
                width={24}
                className="text-gray-500"
              />
            </div>
          </div>
        )}
        <TableContainer sx={{ maxHeight: 500, minHeight: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {loading ? (
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      <Skeleton animation="wave" height={80} />
                    </TableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow sx={{ height: "10px" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        minWidth: column.minWidth,
                        bgcolor: colors.blueAccent[700],
                      }}
                    >
                      <Typography
                        fontSize="1rem"
                        fontWeight="bold"
                        sx={{ paddingRight: "1rem", color: colors.grey[100] }}
                        component={"span"}
                        variant={"body2"}
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {loading ? (
                // Show Skeleton when data is loading
                Array.from({ length: 8 }).map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns?.map((column) => (
                      <TableCell key={column.id}>
                        <Skeleton animation="wave" height={40} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : filtereData?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <Typography variant="h6" color="error" component={"span"}>
                      We don't have any jobs that fit your profile right now.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filtereData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            padding: "7px",
                            paddingLeft: "10px",
                          }}
                        >
                          <Box>
                            {column.id === "action" ? (
                              <Button
                                variant="contained"
                                color="success"
                                size="small"
                                onClick={() => handleApply(row)}
                              >
                                {row.action}
                              </Button>
                            ) : column.format &&
                              typeof row[column.id] === "number" ? (
                              column.format(row[column.id])
                            ) : (
                              <Typography
                                fontSize="0.9rem"
                                fontWeight="normal"
                                component={"span"}
                                variant={"body2"}
                              >
                                {row[column.id]?.toString()}
                              </Typography>
                            )}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {loading ? (
          <Skeleton
            animation="wave"
            height={40}
            width={350}
            className=" mt-2 mb-5 ml-1 flex float-end mr-1"
          />
        ) : (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 15]}
            count={Array.isArray(jobsData) ? jobsData?.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={paginationLabelStyle}
            labelDisplayedRows={({ from, to, count }) => (
              <Typography
                style={{ fontSize: "0.9", fontWeight: "bold" }}
                component={"span"}
                variant={"body2"}
              >
                {from}-{to} of {count}
              </Typography>
            )}
            labelRowsPerPage={
              <Typography
                style={{ fontSize: "0.9rem" }}
                component={"span"}
                variant={"body2"}
              >
                Rows per page
              </Typography>
            }
            style={{ fontSize: "1rem", fontWeight: "bold" }}
          />
        )}
      </Paper>
    </div>
  );
};

export default OpeningJobs;
