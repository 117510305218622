import React, { useEffect, useState } from "react";
import SearchableTable from "../../components/table/SearchableTable";
import { Helmet } from "react-helmet-async";
import { getCompletedInternships } from "../../services/dataService";


const MyInternship = () => {
  const [internshipData, setInternshipData] = useState([]);

  useEffect(() => {
    const fetchInternshipData = async () => {
      try {
        const response = await getCompletedInternships.internshipsData();
        console.log("Fetched Data: ", response); 
        setInternshipData(response?.data?.internships || []);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
    fetchInternshipData();
  }, []);

  const determineAction = (status) => {
    console.log(status)
    return status === "Completed"
      ? "Completed"
      : status === "Apply"
      ? "Apply"
      : status === "Failed"
      ? "Fail"
      : "Enrolled";
  };

  // Modify data to include action based on status
  const modifiedInternshipData = internshipData.map((internship) => ({
    ...internship,
    Action: determineAction(internship.Status),
  }));

  const columns = [
    "SL_No",
    "Domain",
    "Cohort",
    "Status",
    "Action",
  ];

  return (
    <div className="w-full p-10">
      <Helmet>
        <title>EduSkills | Internships</title>
      </Helmet>
      <SearchableTable data={modifiedInternshipData} columns={columns} />
    </div>
  );
};

export default MyInternship;
