import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import CourseModal from "../../components/Modals/CourseModal";
import aws from "../../assets/aws.jpg";
import iot from "../../assets/iot.jpg";
import web from "../../assets/webdevelopment.jpg";
import machine from "../../assets/machinelearning.jpg";
import design from "../../assets/design.jpg";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
const courseObject = {
  is_registrationOpen: true,
  courses: [
    {
      courseImage: aws,
      courseName: "Introduction to AWS",
      courseDescription:
        "Learn the basics of React and build interactive user interfaces.Covers key AWS services with hands-on experience.Suitable for beginners and experienced developers.",
      enroll: true,
    },
    {
      courseImage: iot,
      courseName: "JavaScript Fundamentals",
      courseDescription:
        "Master the fundamentals of JavaScript programming language.Enhance your skills as a JavaScript developer.",
      enroll: true,
    },
    {
      courseImage: web,
      courseName: "Python for Data Science",
      courseDescription:
        "Explore Python's capabilities for data analysis and machine learning.Learn to handle data, create APIs, and deploy applications.Suitable for aspiring full-stack developers.",
      enroll: true,
    },
    {
      courseImage: machine,
      courseName: "Web Development with Node.js",
      courseDescription:
        "Build scalable web applications using Node.js and Express.Dive into supervised and unsupervised learning, feature engineering, and model evaluation.Suitable for beginners and those looking to deepen their understanding of machine learning.",
      enroll: true,
    },
    {
      courseImage: design,
      courseName: "Introduction to Machine Learning",
      courseDescription:
        "An overview of machine learning concepts and algorithms.Explore advanced topics like deep learning, natural language processing, and reinforcement learning.Designed for experienced practitioners.",
      enroll: true,
    },
    {
      courseImage: design,
      courseName: "Introduction to Machine Learning",
      courseDescription:
        "An overview of machine learning concepts and algorithms.Explore advanced topics like deep learning, natural language processing, and reinforcement learning.Designed for experienced practitioners.",
      enroll: true,
    },
    {
      courseImage: design,
      courseName: "Introduction to Machine Learning",
      courseDescription:
        "An overview of machine learning concepts and algorithms.Explore advanced topics like deep learning, natural language processing, and reinforcement learning.Designed for experienced practitioners.",
      enroll: true,
    },
  ],
};

const Courses = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseData, setCourseData] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    // Simulate fetching data (replace this with your actual data fetching logic)
    const fetchData = async () => {
      try {
        // Simulating an API call delay
        setTimeout(() => {
          setCourseData(courseObject.courses);
          setLoading(false);
        }, 2000);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const openModal = (course) => {
    setSelectedCourse(course);
  };

  const closeModal = () => {
    setSelectedCourse(null);
  };
  const toggleEnrollStatus = (index) => {
    const updatedCourses = [...courseData];
    updatedCourses[index].enroll = !updatedCourses[index].enroll;
    setCourseData(updatedCourses);
  };
  return (
    <div className=" m-5 ">
      <Helmet>
        <title> EduSkills | Apply Internship </title>
      </Helmet>
      <div className=" w-full">
        {courseObject.is_registrationOpen ? (
          <div className="flex flex-wrap gap-4 items-center">
            {loading ? (
              // Display skeleton while loading
              Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 w-full sm:w-96 border-2 rounded-md shadow-md"
                >
                  <Skeleton
                    sx={{ height: 190 }}
                    animation="wave"
                    variant="rectangular"
                  />
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="40%"
                    className=" shadow-md ml-2"
                  />
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="20%"
                    className=" shadow-md ml-2"
                  />
                  <div className=" flex justify-end ml-2 mr-2 mb-2 ">
                    {/* <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={30}
                    width="20%"
                    className=" rounded-md shadow-md"
                  /> */}
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      height={30}
                      width="20%"
                      className=" rounded-md shadow-md"
                    />
                  </div>
                </div>
              ))
            ) : (
              <>
                {courseData?.map((course, index) => (
                  <div
                    key={index}
                    className="flex-shrink-0 w-full sm:w-96 border-2 rounded-md shadow-md"
                  >
                    <img
                      src={course.courseImage}
                      alt={course.courseName}
                      className="object-fill h-40 w-full mb-4 rounded-t-md"
                    />
                    <h2 className="text-lg font-semibold ml-3">
                      {course.courseName}
                    </h2>
                    <p className="text-sm p-3">
                      {course.courseDescription
                        .split(" ")
                        .slice(0, 10)
                        .join(" ") + " ..."}
                    </p>
                    <div className="flex justify-end ml-2 mr-2 mb-2">
                      {/* {course.enroll ? (
                      <button
                        className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-md"
                        onClick={() => toggleEnrollStatus(index)}
                      >
                        Enroll
                      </button>
                    ) : (
                      <button className="mt-2 bg-yellow-300 text-white px-2 py-2 rounded-md">
                        Enrolled
                      </button>
                    )} */}
                      <button
                        className="mt-2 bg-sky-500 text-white px-4 py-2 rounded-md hover:bg-sky-600 hover:scale-105"
                        onClick={() => openModal(course)}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ) : (
          <div className=" flex flex-col h-[520px] justify-center items-center">
            <h2 className=" text-center  justify-center items-center font-semibold text-lg italic">
              Please be informed that there is no ongoing internship
              registration process.
            </h2>
            <button
              className=" rounded-md bg-sky-600 hover:bg-sky-800 text-white px-4 py-1 hover:scale-105 text-base mt-5"
              onClick={() => navigate("/")}
            >
              Go Home
            </button>
          </div>
        )}
      </div>
      <CourseModal
        isOpen={selectedCourse}
        onClose={closeModal}
        course={selectedCourse}
        onEnroll={toggleEnrollStatus}
      />
    </div>
  );
};

export default Courses;
