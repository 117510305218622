import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { Icon } from "@iconify/react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

const ResumePreview = ({ data, onSubmit, handleUpdate }) => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onAgree = () => {
    setIsAgreed(!isAgreed);
  };
  const renderCareerObjective = () => {
    const objective = data?.objective;
    return (
      <div className=" mb-8">
        <h2 className="text-lg font-bold mb-2">Career Objective:</h2>
        <p className=" text-base font-medium text-justify">{objective}</p>
      </div>
    );
  };
  const renderCandidateProfile = () => {
    const { name, email, alt_email, alt_phone, phone, city, profile_pic } =
      data?.profile;
    // console.log(typeof data?.profile, "all data");
    return (
      <div className="mb-8 border-b-2 border-slate-200 pb-5">
        {/* <h3 className="text-lg font-bold mb-2">Candidate Profile:</h3> */}
        <div className="flex justify-between gap-10">
          <div className=" text-base font-medium">
            <p className=" text-lg font-semibold">{name}</p>
            <p className=" flex gap-2">
              <Icon
                icon="material-symbols:mail"
                height={20}
                width={20}
                className="mt-1"
              />
              {email} /{alt_email}
            </p>
            <p className=" flex gap-2">
              <Icon
                icon="ri:phone-fill"
                height={20}
                width={20}
                className=" mt-1"
              />
              {phone} / {alt_phone}
            </p>
            <p className=" flex gap-2">
              <Icon
                icon="ic:baseline-place"
                height={20}
                width={20}
                className=" mt-1"
              />
              {city}
            </p>
          </div>
          {/* {profilePic && profilePic instanceof File && (
            <img
              src={URL.createObjectURL(profilePic)}
              alt="Profile Pic"
              className="h-20 w-20 rounded-full"
            />
          )} */}
          {profile_pic && (
            <div>
              <img
                src={profile_pic}
                alt="Profile Pic"
                className=" h-14 w-14 md:h-32 md:w-28 rounded-md"
              />
            </div>
          )}
        </div>
        {/* <div className=" h-0.5 w-full bg-slate-200 mt-5"></div> */}
      </div>
    );
  };

  const renderEducationDetails = () => {
    // console.log(data.education);
    const educationDetails = data?.education?.map((education, index) => (
      <tr key={index} className=" p-2 border-b-2 border-gray-100">
        <td className="font-bold pl-5">
          {" "}
          {education?.degree === "Other"
            ? education?.otherDegree
            : education?.degree}
        </td>
        <td className=" font-semibold pl-5">{education?.school}</td>
        <td className=" font-semibold pl-5">{education?.percentage}</td>
        <td className=" font-semibold ">{education?.passoutYear}</td>
      </tr>
    ));

    return (
      <div>
        <h3 className="text-lg font-bold mb-2">Education Details:</h3>
        <div className="border-2 border-gray-200 rounded-md overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className=" text-left font-semibold text-base h-4 bg-slate-300 w-full shadow-sm text-slate-800">
                <th className=" pl-3">Course/Degree</th>
                <th className=" pl-5">School/University</th>
                <th>Grade/Score</th>
                <th>Year</th>
              </tr>
            </thead>
            <tbody>{educationDetails}</tbody>
          </table>
        </div>
      </div>
    );
  };
  const renderExperienceDetails = () => {
    const experienceDetails = data?.internship?.map((experience, index) => {
      // Format fromDate and toDate
      const formattedFromDate = new Date(experience?.fromDate).toLocaleString(
        "en-us",
        {
          month: "short",
          year: "numeric",
        }
      );
      const formattedToDate = new Date(experience?.toDate).toLocaleString(
        "en-us",
        {
          month: "short",
          year: "numeric",
        }
      );
      const formattedFromDateDisplay = formattedFromDate.replace(
        /(\w+) (\d+)/,
        "$1. $2"
      );
      const formattedToDateDisplay = formattedToDate.replace(
        /(\w+) (\d+)/,
        "$1. $2"
      );

      return (
        <div key={index} className="mb-8">
          <div>
            <div className="flex justify-between text-base">
              <p className="font-bold">{experience?.jobTitle}</p>
              <p className=" font-medium italic text-sm">{`${formattedFromDateDisplay} - ${formattedToDateDisplay}`}</p>
            </div>
            <p className="font-semibold italic">{experience?.companyName}</p>
            <p>{experience?.location}</p>
            <p>{experience?.roleDescription}</p>
          </div>
        </div>
      );
    });

    return (
      <div>
        <h3 className="text-lg font-bold mb-2">Internships Details:</h3>
        {experienceDetails}
      </div>
    );
  };

  // const renderExperienceDetails = () => {
  //   const experienceDetails =
  //     data?.experienceDetails[0]?.experiencesDetails?.map(
  //       (experience, index) => (
  //         <div key={index} className="mb-8">
  //           <div>
  //             <div className=" flex justify-between">
  //               <p className="font-bold text-base">{experience?.jobTitle}</p>
  //               <p>{`${experience?.fromDate} - ${experience?.toDate}`}</p>
  //             </div>
  //             <p className=" font-semibold italic">{experience?.companyName}</p>
  //             <p>{experience?.location}</p>
  //             <p>{experience?.roleDescription}</p>
  //           </div>
  //         </div>
  //       )
  //     );

  //   return (
  //     <div>
  //       <h3 className="text-lg font-bold mb-2">Internships Details:</h3>
  //       {experienceDetails}
  //     </div>
  //   );
  // };

  const renderProjectDetails = () => {
    const projectDetails = data?.projects?.map((project, index) => (
      <div key={index} className="mb-8">
        <div>
          <p className="font-bold">{project?.projectTitle}</p>
          <p>{project?.projectDescription}</p>
        </div>
      </div>
    ));

    return (
      <div>
        <h3 className="text-lg font-bold mb-2 mt-8">Project Details:</h3>
        {projectDetails}
      </div>
    );
  };

  const renderSkillsDetails = () => {
    const skills = data?.skills;

    return (
      <div className="mb-8">
        {Array.isArray(skills) && skills.length > 0 && (
          <div>
            <h4 className="font-bold mb-2 text-lg">Skills:</h4>
            {skills.length <= 5 ? (
              <ul>
                {skills?.map((skill, index) => (
                  <li key={index} className="list-disc ml-5">
                    {skill}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="grid grid-cols-2">
                <ul>
                  {skills
                    .slice(0, Math.ceil(skills.length / 2))
                    .map((skill, index) => (
                      <li key={index} className="list-disc ml-5">
                        {skill}
                      </li>
                    ))}
                </ul>
                <ul>
                  {skills
                    .slice(Math.ceil(skills.length / 2))
                    .map((skill, index) => (
                      <li key={index} className="list-disc ml-5">
                        {skill}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  // Add more functions for rendering other sections

  return (
    <div className="p-4 md:p-8 lg:p-12 w-full md:w-10/12 rounded-md shadow-md mx-auto justify-center">
      <h2 className="text-2xl font-bold mb-4">Review Your Information</h2>

      {renderCandidateProfile()}
      {renderCareerObjective()}
      {renderSkillsDetails()}
      {renderEducationDetails()}
      {renderProjectDetails()}
      {renderExperienceDetails()}

      {/* Add calls to other rendering functions for other sections */}

      <FormControlLabel
        control={
          <Checkbox checked={isAgreed} onChange={onAgree} color="secondary" />
        }
        label="I declare that above information is true to the best of my knowledge."
      />
      <div className=" flex justify-between mt-2">
        <Button
          className="mt-4"
          variant="contained"
          color="success"
          onClick={handleUpdate}
        >
          Edit Resume
        </Button>

        <div className=" flex gap-5 text-white">
          <LoadingButton
            loading={loading}
            variant="contained"
            color="secondary"
            onClick={onSubmit}
            disabled={!isAgreed}
          >
            <h2 className=" text-white">Submit1</h2>
            <Icon
              icon="mingcute:arrow-right-line"
              height={20}
              width={20}
              className=" text-white"
            />
          </LoadingButton>
          {/* <Button
            className="mt-4"
            variant="contained"
            color="secondary"
            onClick={onSubmit}
            disabled={!isAgreed}
          >
            <h2 className=" text-white">Continue Application</h2>
            <Icon
              icon="mingcute:arrow-right-line"
              height={20}
              width={20}
              className=" text-white"
            />
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default ResumePreview;
