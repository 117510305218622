import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getInternshipCertificateDetails } from "../../services/dataService";
import { CloudUpload, Verified, Pending } from "@mui/icons-material";
import { VerifiedIcon, XCircle } from "lucide-react";
import { BASE_URL } from "../../services/configUrls";
import { useNavigate } from "react-router-dom";

const domainRegexMap = {
  1: /^https:\/\/www\.credly\.com\//,
  2: /^https:\/\/www\.credly\.com\//,
  3: /^https:\/\/www\.credly\.com\//,
  11: /^https:\/\/verify\.skilljar\.com\//,
  13: /^https:\/\/verify\.skilljar\.com\//,
  16: /^https:\/\/g\.dev\//,
  18: /^https:\/\/g\.dev\//,
  25: /^https:\/\/verify\.skilljar\.com\//,
  32: /^https:\/\/www\.cloudskillsboost\.google\/public_profiles\//,
  6: /^https:\/\/www\.credly\.com\//,
  20: /^https:\/\/www\.credly\.com\//,
  12: /^https:\/\/training\.fortinet\.com\//,
  9: /^https:\/\/www\.credly\.com\//,
  19: /^https:\/\/www\.credly\.com\//,
  17: /^https:\/\/www\.credly\.com\//,
};

const FileUpload = () => {
  const [formData, setFormData] = useState({});
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const navigate = useNavigate();

  const fetchCourseData = async () => {
    setLoading(true);
    try {
      const response =
        await getInternshipCertificateDetails.certificateDetails();
      setCourseData(response?.data);
    } catch (error) {
      console.error("Error fetching course data:", error);
      setError(error);
      toast.error("Failed to fetch course data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, []);

  const handleFileChange = (course_id, event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFormData((prev) => ({
        ...prev,
        [course_id]: {
          ...prev[course_id],
          file: selectedFile,
        },
      }));
    } else {
      toast.error("Please select a PDF file");
    }
  };

  const handleTextChange = (course_id, domain_id, event) => {
    const inputValue = event.target.value;
    const regex = domainRegexMap[domain_id];

    // Validate the input based on the domain regex
    if (regex && !regex.test(inputValue)) {
      toast.error(`Invalid URL format for the domain: ${inputValue}`);
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [course_id]: {
        ...prev[course_id],
        text: inputValue,
      },
    }));
  };

  // const handleVerification = async () => {
  //   try {
  //     setVerifying(true);
  //     await axios.get(`${BASE_URL}/certificate/verify`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //       },
  //     });
  //     await fetchCourseData(); // Re-fetch course data after verification completes
  //   } catch (error) {
  //     console.error("Verification error:", error);
  //   } finally {
  //     setVerifying(false);
  //   }
  // };

  // const handleVerification = async () => {
  //   try {
  //     setVerifying(true);

  //     // Step 1: Fetch course data (Verification)
  //     try {
  //       await axios.get(`${BASE_URL}/certificate/verify`, {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         },
  //       });
  //       await fetchCourseData(); // Fetch course data after verification
  //     } catch (verificationError) {
  //       console.error("Verification error:", verificationError);
  //       toast.error("Verification failed. Please try again.");
  //       return; // Exit early if verification fails
  //     }

  //     // Step 2: Check domain_id and conditionally hit the assessment API
  //     const shouldSkipAssessment = courseData?.courses?.some((course) =>
  //       [9, 25, 6, 20, 13, 33].includes(course.domain_id)
  //     );

  //     if (!shouldSkipAssessment) {
  //       try {
  //         await axios.get(`${BASE_URL}/assessment/generate_assessment`, {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //           },
  //         });

  //         // Show success message for assessment generation
  //         toast.success("You can now start your assessment.");
  //       } catch (assessmentError) {
  //         console.error("Assessment generation error:", assessmentError);
  //         toast.error("Assessment generation failed. Please try again.");
  //         return; // Exit early if assessment generation fails
  //       }
  //     } else {
  //       toast.info("Assessment generation skipped for this domain.");
  //     }

  //     // Step 3: Navigate to /internship
  //     navigate("/internship");
  //   } finally {
  //     setVerifying(false);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const courseDataArray = [];

    const encodeFileToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result.split(",")[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    // First, collect all courses with actual data
    const coursesWithData = [];

    for (const course of courseData?.courses || []) {
      const courseDetails = formData[course.course_id];

      // Skip if no data provided for this course
      if (!courseDetails) continue;

      // Skip courses that don't need submission
      if (course.status !== "0" && course.status !== "3") continue;

      // Check if there's actual data (file or text)
      const hasFile = !!courseDetails.file;
      const hasText = !!courseDetails.text;

      if (hasFile || hasText) {
        coursesWithData.push({
          course,
          details: courseDetails,
          hasFile,
          hasText,
        });
      }
    }

    // Process only courses that have data
    for (const { course, details, hasFile, hasText } of coursesWithData) {
      let courseObject = {
        course_name: course.course_name,
        domain_id: course.domain_id,
        course_id: course.course_id,
      };

      // Only include text if it exists
      if (hasText) {
        courseObject.text = details.text;
      }

      // Only include file if it exists
      if (hasFile) {
        courseObject.file = await encodeFileToBase64(details.file);
      }

      // Don't include null fields
      if (courseObject.text === undefined) delete courseObject.text;
      if (courseObject.file === undefined) delete courseObject.file;

      courseDataArray.push(courseObject);
    }

    // Validate that we have at least one course to submit
    if (courseDataArray.length === 0) {
      toast.error("Please provide at least one certificate or URL to submit");
      return;
    }

    try {
      setUploading(true);

      console.log("Submitting data:", courseDataArray); // For debugging

      await axios.post(
        `${BASE_URL}/domain/course_certificate_upload`,
        { data: courseDataArray },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Submission successful");
      // handleVerification();
      if (courseDataArray.length > 0) {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("Failed to submit network error check your network");
    } finally {
      setUploading(false);
    }
  };

  const isAllCoursesVerified = courseData?.courses?.every(
    (course) => course.status === "2"
  );

  const isAnyCourseInProgress = courseData?.courses?.every(
    (course) => course.status === "1"
  );

  const isAnyCourseInRejected = courseData?.courses?.some(
    (course) => course.status === "3"
  );

  // const showComingSoonMessage = courseData?.courses?.some(
  //   (course) => course.domain_id === 13 || course.domain_id === 9
  // );

  if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f0f0",
        padding: 2,
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : verifying ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography sx={{ marginTop: 2 }}>
            Verification under Process
          </Typography>
        </Box>
      ) : (
        <Card
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", sm: "600px", md: "900px" },
            padding: { xs: 2, sm: 3 },
            boxShadow: 5,
            borderRadius: 3,
            backgroundColor: "#ffffff",
          }}
        >
          <CardContent>
            {/* {showComingSoonMessage && (
              <Typography
                variant="body2"
                sx={{
                  color: "blue",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 2,
                  fontSize: "1rem",
                }}
              >
                The Certificate Upload Will Open Soon.
              </Typography>
            )} */}

            {isAnyCourseInProgress && (
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "green",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 2,
                  fontSize: "1rem",
                  backgroundColor: "#f0f4f7",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Pending
                  className="text-yellow-500"
                  fontSize="inherit"
                  sx={{ fontSize: "4rem", marginBottom: "8px" }}
                />
                Your certificate has been successfully uploaded. The
                verification status will be notified soon.
              </Typography>
            )}

            {isAllCoursesVerified && (
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "green",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 2,
                  fontSize: "1rem",
                  backgroundColor: "#f0f4f7",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <VerifiedIcon
                  className="text-green-500"
                  fontSize="inherit"
                  sx={{ fontSize: "4rem", marginBottom: "8px" }}
                />
                Your all certificates are verified successfully.
              </Typography>
            )}

            {!isAnyCourseInProgress && !isAllCoursesVerified && (
              <>
                {/* Certificate upload or URL input section */}
                <Typography
                  variant="h5"
                  component="h1"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: 2,
                  }}
                >
                  Upload Certificate or URLs for Courses
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)" },
                    gap: 2,
                  }}
                >
                  {courseData?.courses
                    ?.filter(
                      (course) => course.status === "0" || course.status === "3"
                    )
                    .map((course) => (
                      <div
                        key={course.course_id}
                        style={{ marginBottom: "16px" }}
                      >
                        {/* Input for course status 0 */}
                        {courseData.status === 0 && (
                          <div className="flex items-center gap-4">
                            {/* <CloudUpload
                              className="text-gray-500"
                              fontSize="large"
                            /> */}
                            <div className="w-full">
                              <input
                                type="file"
                                required
                                onChange={(e) =>
                                  handleFileChange(course.course_id, e)
                                }
                                className="w-full text-gray-500 font-medium text-base bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:mr-4 file:bg-gray-800 file:hover:bg-gray-700 file:text-white rounded"
                              />
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ marginTop: 1 }}
                              >
                                Only PDF files are allowed for{" "}
                                {course.course_name}.
                              </Typography>
                              {/* <IconButton
                                  onClick={() =>
                                    handleOpenDialog(course.domain_id)
                                  }
                                  size="small"
                                  sx={{ marginLeft: 1 }}
                                >
                                  <Info />
                                </IconButton> */}
                            </div>
                          </div>
                        )}

                        {course.status === "3" && courseData.status === 0 && (
                          <Typography
                            variant="body2"
                            sx={{
                              color: "red",
                              fontWeight: "bold",
                              marginBottom: 1,
                            }}
                          >
                            Your {course.course_name} is rejected. Please
                            re-upload the PDF.
                          </Typography>
                        )}

                        {courseData.status === 1 && (
                          <div className="flex items-center gap-2">
                            <TextField
                              label={`Enter URL for ${course.course_name}`}
                              fullWidth
                              size="small"
                              required
                              onChange={(e) =>
                                handleTextChange(
                                  course.course_id,
                                  course.domain_id,
                                  e
                                )
                              }
                              sx={{ marginTop: 2 }}
                            />
                            {/* <IconButton
                                onClick={() =>
                                  handleOpenDialog(course.domain_id)
                                }
                                size="small"
                                sx={{ marginLeft: 1 }}
                              >
                                <Info />
                              </IconButton> */}
                          </div>
                        )}
                        {/* Show the rejected message for courses with status "3" */}
                        {course.status === "3" && courseData.status === 1 && (
                          <Typography
                            variant="body2"
                            sx={{
                              color: "red",
                              fontWeight: "bold",
                              marginBottom: 1,
                            }}
                          >
                            Your {course.course_name} is rejected. Please
                            re-upload or re-enter the URL.
                          </Typography>
                        )}

                        {courseData.status === 2 && (
                          <div>
                            {course.is_url === "0" &&
                            (course.status === "0" || course.status === "3") ? (
                              <div className="flex items-center gap-4">
                                {/* <CloudUpload
                                    className="text-gray-500"
                                    fontSize="large"
                                  /> */}
                                <div className="w-full">
                                  <input
                                    type="file"
                                    required
                                    onChange={(e) =>
                                      handleFileChange(course.course_id, e)
                                    }
                                    className="w-full text-gray-500 font-medium text-base bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:mr-4 file:bg-gray-800 file:hover:bg-gray-700 file:text-white rounded"
                                  />
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{ marginTop: 1 }}
                                  >
                                    Only PDF files are allowed for{" "}
                                    {course.course_name}.
                                  </Typography>

                                  {course.status === "3" &&
                                    courseData.status === 2 && (
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color: "red",
                                          fontWeight: "bold",
                                          marginBottom: 1,
                                          marginTop: 1,
                                        }}
                                      >
                                        Your {course.course_name} is rejected.
                                        Please re-upload the PDF.
                                      </Typography>
                                    )}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <TextField
                                  label={`Enter URL for ${course.course_name}`}
                                  fullWidth
                                  size="small"
                                  required
                                  onChange={(e) =>
                                    handleTextChange(
                                      course.course_id,
                                      course.domain_id,
                                      e
                                    )
                                  }
                                  sx={{ marginTop: 2 }}
                                />
                                {course.status === "3" &&
                                  courseData.status === 2 && (
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: "red",
                                        fontWeight: "bold",
                                        marginBottom: 1,
                                        marginTop: 1,
                                      }}
                                    >
                                      Your {course.course_name} is rejected.
                                      Please re-upload or re-enter the URL.
                                    </Typography>
                                  )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  fullWidth
                  disabled={
                    uploading ||
                    !courseData?.courses?.some(
                      (course) => course.status === "0" || course.status === "3"
                    )
                  }
                  sx={{
                    marginTop: 3,
                  }}
                >
                  {uploading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      )}

      {/* <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Certificate Upload Instructions</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {selectedImages.map((image, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <img
                  src={image} // Replace with actual image paths or URLs
                  alt={`Certificate guideline ${index + 1}`}
                  style={{ width: "100%", height: "auto", borderRadius: 8 }}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog> */}
    </Box>
  );
};

const ErrorComponent = ({ error }) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <div className="flex items-center justify-center mb-6">
          <XCircle className="text-red-500 w-16 h-16" />
        </div>
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
          Oops! Something went wrong
        </h2>
        <p className="text-center text-gray-600 mb-6">
          We couldn't fetch the course data. Please try again later.
        </p>
        <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
          <p className="text-sm text-red-700">Error details: {error.message}</p>
        </div>
        <div className="flex justify-center">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 ease-in-out"
            onClick={() => window.location.reload()}
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
