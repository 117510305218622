// Helper function to check if the JWT is expired
export const isTokenExpired = (token) => {
    if (!token) return true;
  
    // JWT token structure: [header].[payload].[signature]
    const base64Url = token.split('.')[1]; // Get the payload part of the token
    if (!base64Url) return true; // Invalid token format
  
    // Base64 decode the payload
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  
    const decoded = JSON.parse(jsonPayload);
  
    // Check if the token contains an `exp` claim
    if (!decoded.exp) return true;
  
    // Convert expiration time from seconds to milliseconds and compare with current time
    const expirationTime = decoded.exp * 1000;
    return Date.now() >= expirationTime; // True if expired, false if not expired
  };
  