import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { tokens } from "../../theme";
import {
  Button,
  MenuItem,
  Select,
  TableFooter,
  Typography,
  useTheme,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import CoursesModal from "../../components/Modals/CoursesModal";
import Skeleton from "@mui/material/Skeleton";
const columns = [
  { id: "academy", label: "Academy", minWidth: 170 },
  { id: "domain", label: "Domain", minWidth: 100 },
  { id: "details", label: "Details", minWidth: 170 },
  { id: "isEnroll", label: "Status", minWidth: 30 },
  { id: "action", label: "Download", minWidth: 30 },
];
const createData = (academy, domain, details, isEnroll) => {
  return { academy, domain, details, isEnroll, action: "View More" };
};
const coursesData = [
  createData(
    "AWS",
    "AWS Foundation",
    "Learn the fundamentals of AWS services.",
    true
  ),
  createData(
    "Google Cloud",
    "Cloud Computing Basics",
    "Introduction to Google Cloud Platform services.",
    true
  ),
  createData(
    "Microsoft Azure",
    "Azure Fundamentals",
    "Explore the basics of Microsoft Azure services.",
    true
  ),
  createData(
    "ReactJS",
    "Frontend Development",
    "Master the art of building user interfaces with ReactJS.",
    true
  ),
  createData(
    "Node.js",
    "Backend Development",
    "Learn server-side development with Node.js.",
    true
  ),
  createData(
    "Python",
    "Data Science",
    "Discover the world of data science using Python.",
    true
  ),
  createData(
    "Machine Learning",
    "Advanced AI",
    "Discover the world of data science using Python.",
    true
  ),
  createData(
    "Web Design",
    "UI/UX Basics",
    "Explore the basics of web design and user experience.",
    true
  ),
  createData(
    "Blockchain",
    "Decentralized Applications",
    "Build decentralized applications with blockchain technology.",
    true
  ),
  createData(
    "Cybersecurity",
    "Ethical Hacking",
    "Become an ethical hacker and enhance cybersecurity skills.",
    true
  ),
  createData(
    "Cybersecurity",
    "Ethical Hacking",
    "Become an ethical hacker and enhance cybersecurity skills.",
    true
  ),
];

const Coursess = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseData, setCourseData] = useState(coursesData);
  const [loading, setLoading] = useState(true);

  //....................
  useEffect(() => {
    // Simulating data fetching delay
    const fetchData = async () => {
      try {
        // Simulating API call or data fetching
        setLoading(true);
        await new Promise((resolve) => setTimeout(resolve, 1500));
        setCourseData(coursesData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  //....................
  const openModal = (row) => {
    setSelectedCourse(row);
  };

  const closeModal = () => {
    setSelectedCourse(null);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filteredData = coursesData.filter((row) => {
      // Search by domain name
      return row.academy.toLowerCase().includes(query);
    });

    setCourseData(filteredData);
    setPage(0);
    setSearchQuery(query);
  };

  const filteredData = courseData.filter((row) => {
    const searchTerms = Object.values(row).join(" ").toLowerCase();
    return searchTerms.includes(searchQuery.toLowerCase());
  });
  const paginationLabelStyle = {
    fontSize: "1.6rem",
    fontWeight: "bold",
  };
  return (
    <div className="m-5">
      <Helmet>
        <title> EduSkills | Student </title>
      </Helmet>
      <Paper sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
        {loading ? (
          <Skeleton
            animation="wave"
            height={70}
            width={200}
            className=" m-2 ml-5"
          />
        ) : (
          <div className="m-2">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="p-2 rounded-md w-full md:w-60 shadow-sm outline-sky-300 border-2 border-gray-300 text-gray-900"
            />
          </div>
        )}
        <TableContainer sx={{ maxHeight: 500, minHeight: 500 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              {loading ? (
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      <Skeleton animation="wave" height={80} />
                    </TableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow sx={{ height: "10px" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        minWidth: column.minWidth,
                        bgcolor: colors.blueAccent[700],
                      }}
                    >
                      <Typography fontSize="1rem" fontWeight="bold">
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {loading ? (
                // Show Skeleton when data is loading
                Array.from({ length: 10 }).map((_, rowIndex) => (
                  <TableRow key={rowIndex} style={{ height: "10px" }}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{ height: "10px", padding: "5px" }}
                      >
                        <Skeleton animation="wave" height={40} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : filteredData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Typography variant="h6" color="error">
                      No records found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      tabIndex={-1}
                      key={index}
                      style={{ height: "10px" }}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ height: "10px", padding: "7px" }}
                        >
                          {column.id === "isEnroll" ? (
                            <>
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                              >
                                Enroll
                              </Button>
                            </>
                          ) : column.id === "action" ? (
                            <Button
                              variant="contained"
                              color="success"
                              size="small"
                              onClick={() => openModal(row)}
                            >
                              {row.action}
                            </Button>
                          ) : column.format &&
                            typeof row[column.id] === "number" ? (
                            column.format(row[column.id])
                          ) : (
                            <Typography fontSize="0.9rem" fontWeight="normal">
                              {row[column.id].toString()}
                            </Typography>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {loading ? (
          ""
        ) : (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={courseData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={paginationLabelStyle}
            labelDisplayedRows={({ from, to, count }) => (
              <Typography style={{ fontSize: "0.9", fontWeight: "bold" }}>
                {from}-{to} of {count}
              </Typography>
            )}
            labelRowsPerPage={
              <Typography style={{ fontSize: "0.9rem" }}>
                Rows per page
              </Typography>
            }
            style={{ fontSize: "1rem", fontWeight: "bold" }}
          />
        )}
      </Paper>
      <CoursesModal
        isOpen={selectedCourse}
        onClose={closeModal}
        course={selectedCourse}
      />
    </div>
  );
};

export default Coursess;
