import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { instructionService } from "../../services/dataService";
import { InfinitySpin } from "react-loader-spinner";

const Assessments = () => {
  const [instructionData, setInstructionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/instructions");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await instructionService.instruction();
        // const assessmentData = response?.data?.data[0];

        const assessmentData = response?.data?.data;
         console.log(assessmentData);

        if (assessmentData && assessmentData.length > 0) {
          // Check if there is only one assessment
          if (assessmentData.length === 1) {
            // Check if the assessment status is "start"
            if (assessmentData[0].status === "start") {
              localStorage.setItem("i5St@r7t!", "@ll0w3d2G!v3!3x@M");
              localStorage.setItem(
                "ASSESSMENT_ID",
                assessmentData[assessmentData.length - 1]?.assessment_id
              );
            } else {
              localStorage.setItem("i5St@r7t!", "@ll0w3d9G!v3!3x@M");
            }
          } else {
            // Get the status of the last assessment
            const lastAssessmentStatus =
              assessmentData[assessmentData.length - 1]?.status;

            // Check if the last assessment status is "start"
            if (lastAssessmentStatus === "start") {
              localStorage.setItem(
                "ASSESSMENT_ID",
                assessmentData[assessmentData.length - 1]?.assessment_id
              );
              localStorage.setItem("i5St@r7t!", "@ll0w3d2G!v3!3x@M");
            } else {
              localStorage.setItem("i5St@r7t!", "@ll0w3d9G!v3!3x@M");
            }
          }
        } else {
          // If assessmentData is empty or does not exist
          localStorage.setItem("i5St@r7t!", "@ll0w3d9G!v3!3x@M");
        }

        setInstructionData(assessmentData);
      } catch (error) {
        console.error(error);
        localStorage.setItem("i5St@r7t!", "@ll0w3d9G!v3!3x@M");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container mx-auto mt-5 p-3">
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <InfinitySpin
            visible={true}
            width={200}
            color="#4fa94d"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      ) : (
        <div>
          <h2 className="text-lg font-bold tracking-wider h-10 rounded-md shadow-sm p-1">
            Assessments
          </h2>
          {instructionData ? (
            <div className="overflow-x-auto">
              <table className="min-w-full shadow-sm border-gray-300 mt-8">
                <thead>
                  <tr className="text-base">
                    <th className="py-2 px-4 border-b">SL</th>
                    <th className="py-2 px-4 border-b text-left">Exam Name</th>
                    <th className="py-2 px-4 border-b">Duration</th>
                    <th className="py-2 px-4 border-b">No.Mcq</th>
                    <th className="py-2 px-4 border-b">
                      {instructionData.status === "start"
                        ? "Start Date"
                        : "Grade"}
                    </th>
                    <th className="py-2 px-4 border-b">
                      {instructionData.status === "start"
                        ? "End Date"
                        : "Completed Date"}
                    </th>

                    <th className="py-2 px-4 border-b">Status</th>
                  </tr>
                </thead>

                {instructionData.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <td className="py-2 px-4 border-b text-center">
                        {item.sl_no}
                      </td>
                      <td className="py-2 px-4 border-b">{item.assessment}</td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.duration} Mins
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.mcq}
                      </td>
                      <td
                        className={`py-2 px-4 border-b text-center font-${
                          item.status === "start" ? "" : "bold"
                        }`}
                      >
                        {item.status === "start" ? item.start_date : item.grade}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {item.end_date}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        <button
                          className={`w-auto h-8 bg-${
                            item.status === "start"
                              ? "sky"
                              : item.status === "completed"
                              ? "green"
                              : "red"
                          }-400 rounded-md p-1 text-white text-base px-2 py-1`}
                          onClick={handleNext}
                          disabled={item.status !== "start"}
                        >
                          {item.status}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          ) : (
            <div className="flex flex-col h-20 w-full rounded-md">
              <h3 className="text-center mt-5 text-base tracking-normal">
                No Assessments available
              </h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Assessments;
