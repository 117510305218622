import React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import LogoDark from "../../assets/imgs/logo-dark.svg";
import LogoLight from "../../assets/imgs/logo-light.svg";
import Typography from "@mui/material/Typography";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { createTheme, styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { setActiveIndex } from "../../store/Slices/assementSlice";
// import { useTheme } from "@emotion/react";
// import { tokens } from "../../theme";
// import { FormGroup, useMediaQuery } from "@mui/material";
import { FormGroup } from "@mui/material";
import { LinearProgress } from "@mui/material";
import Questions from "../../components/sidebar/Questions";
import { useNavigate } from "react-router-dom";
import { AssementService, AssementSubmit } from "../../services/dataService";
import ResponseComponent from "./ResponseComponent";
import { InfinitySpin } from "react-loader-spinner";
import CommonModal from "../../components/Modals/CommonModal";
import { toast } from "react-toastify";
import Assessments from "./Assessments";

const Assement = () => {
  const storedTimerState = JSON.parse(localStorage.getItem("questionState"));
  const Total_Time = Number(localStorage.getItem("TOTAL_TIME"));
  const Total_Seconds = Number(localStorage.getItem("TOTAL_SECONDS"));
  const submitted = JSON.parse(localStorage.getItem("IS_SUBMITTED"));
  const savedData = JSON.parse(localStorage.getItem("DATA")) || [];
  const initialSeconds = storedTimerState
    ? storedTimerState.seconds
    : Total_Seconds * 60;
  const storedMaximizeCount =
    JSON.parse(localStorage.getItem("MAXIMIZECOUNT")) || 0;
  const storedLogMessages =
    JSON.parse(localStorage.getItem("LOGMESSAGES")) || [];
  const assessmentId = Number(localStorage.getItem("ASSESSMENT_ID"));
  const navigate = useNavigate();
  const [questionData, setQuestionData] = useState(savedData);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const currentIndex = useSelector((state) => state.assementIndex.activeIndex);
  const savedValuesString = localStorage.getItem("submitValues");
  const savedValues = savedValuesString ? JSON.parse(savedValuesString) : [];
  const selectV = localStorage.getItem("selectValues");
  const sv = selectV ? JSON.parse(selectV) : [];
  const [selectedValues, setSelectedValues] = useState(sv);
  const [progress, setProgress] = useState(0);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [ansArray, setAnsArray] = useState(savedValues);
  // const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(true);

  const TotalTimerState = JSON.parse(localStorage.getItem("timerState"));
  const totalSeconds = TotalTimerState ? TotalTimerState.sec : Total_Time * 60;
  const [sec, setSec] = useState(totalSeconds);
  const [maximizeCount, setMaximizeCount] = useState(storedMaximizeCount);
  const [logMessages, setLogMessages] = useState(storedLogMessages);
  const [currentAction, setCurrentAction] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // const options = questionData?.question?.[currentIndex]?.options;
  const quesId = questionData?.question?.[currentIndex]?.question_id;
  const mcqtype = questionData?.question?.[currentIndex]?.mcqtype;

  //........................................................................
  const isStart = localStorage.getItem("i5St@r7t!");

  function handleErrorMessage(message) {
    toast.error(message, {
      autoClose: 3000,
      position: "top-center",
    });
  }

  useEffect(() => {
    if (
      questionData?.length === 0 &&
      response === null &&
      submitted === null &&
      isStart === "@ll0w3d2G!v3!3x@M" &&
      assessmentId
    ) {
      localStorage.setItem("IS_SUBMITTED", false);
      const fetchData = async () => {
        try {
         // setLoading(true);
          const response = await AssementService.question(assessmentId);
          console.log(response);
          const data = response?.data;
          if (data?.question?.length > 0) {
            setQuestionData(data);
            localStorage.setItem("DATA", JSON.stringify(data));

            const totalTime = data?.detail?.duration;
            setSec(totalTime * 60);
            localStorage.setItem("TOTAL_TIME", JSON.stringify(totalTime));

            const totalSeconds = data?.detail?.munite_per_mcq;
            setSeconds(totalSeconds * 60);
            localStorage.setItem("TOTAL_SECONDS", JSON.stringify(totalSeconds));
          } else {
            navigate("/assessment");
          }
        } catch (error) {
           console.log(error);
          setError(error);
          setLoading(false);
          navigate("/assessment");
        } finally {
          //setLoading(false);
        }
      };

      fetchData();
    } else {
      if (isStart === "@ll0w3d9G!v3!3x@M") {
        handleErrorMessage(
          "Currently unable to initiate the exam. Please try again after some time."
        );
        navigate("/");
        localStorage.removeItem("TOTAL_TIME");
        localStorage.removeItem("TOTAL_SECONDS");
        localStorage.removeItem("submitValues");
        localStorage.removeItem("questionState");
        localStorage.removeItem("activeIndex");
        localStorage.removeItem("selectedValues");
        localStorage.removeItem("DATA");
        localStorage.removeItem("LOGMESSAGES");
        localStorage.removeItem("MAXIMIZECOUNT");
        localStorage.removeItem("IS_SUBMITTED");
      }
    }
  }, []);
  //............................................................................

  useEffect(() => {
    // Convert the array to a string and store it in localStorage
    localStorage.setItem("submitValues", JSON.stringify(ansArray));
    localStorage.setItem("selectValues", JSON.stringify(selectedValues));
  }, [ansArray, selectedValues, sec]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  //......................................
  const TotalTime = (sec) => {
    const mins = Math.floor(sec / 60);
    const secs = Math.floor(sec % 60);
    const formattedSecs = secs < 10 ? `0${secs}` : `${secs}`;
    return `${mins}:${formattedSecs}`;
  };
  //......................................
  const getFormattedDate = () => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const year = today.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const todayDate = getFormattedDate();
  //...................................................

  useEffect(() => {
    if (savedData?.length !== 0) {
      let timePassed = 60000 - seconds * 1000;
      const interval = setInterval(() => {
        if (seconds === 0) {
          clearInterval(interval);
          let activeIndex = currentIndex + 1;
          handleNextClick();

          dispatch(setActiveIndex(activeIndex));
          formatTime();
          setSeconds(60);
          setSelectedOption([]);
          return;
        }
        timePassed += 1000;
        const newProgress = (timePassed / 60000) * 100;
        setProgress(newProgress >= 99 ? newProgress : newProgress);
        if (newProgress >= 100) {
          clearInterval(interval);
          if (currentIndex === questionData?.question?.length - 1) {
            handleSubmit();
          } else {
            let activeIndex = currentIndex + 1;
            if (selectedOption?.length === 0) {
              setSelectedOptions((prevOptions) => {
                return {
                  ...prevOptions,
                  [currentIndex]: null,
                };
              });

              setSelectedValues((prevValues) => {
                const newValues = [...prevValues];
                newValues[currentIndex] = null;
                return newValues;
              });
              handleNextClick();
            } else {
              // If an option is selected, update the selectedOptions and selectedValues
              setSelectedOptions((prevOptions) => {
                return {
                  ...prevOptions,
                  [currentIndex]: selectedOption,
                };
              });
              handleNextClick();
              setSelectedValues((prevValues) => {
                const newValues = [...prevValues];
                newValues[currentIndex] = 1;
                return newValues;
              });
            }

            dispatch(setActiveIndex(activeIndex));
            formatTime();
            setSeconds(60);
            setSelectedOption([]);
          }
        }
      }, 1000);
      localStorage.setItem("questionState", JSON.stringify({ seconds }));
      // Update progress every second

      return () => {
        clearInterval(interval);
      };
    }
  }, [currentIndex, selectedOption, seconds]);

  const dispatch = useDispatch();
  const isLastQuestion = currentIndex === questionData?.question?.length - 1;
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 991,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  //............................................

  const handleNextClick = () => {
    if (savedData?.length !== 0) {
      setAnsArray([
        ...ansArray,
        {
          question_id: quesId,
          choices: selectedChoices.length === 0 ? [""] : selectedChoices,
        },
      ]);
      setSelectedChoices([]);
      const questionId = questionData?.question?.[currentIndex]?.question_id;
      let activeIndex = currentIndex + 1;

      // If no option is selected, add null to the selectedOptions and selectedValues
      if (selectedOption === null) {
        setSelectedOptions((prevOptions) => {
          return {
            ...prevOptions,
            [currentIndex]: null,
          };
        });

        setSelectedValues((prevValues) => {
          const newValues = [...prevValues];
          newValues[currentIndex] = null;
          return newValues;
        });
      } else {
        // If an option is selected, update the selectedOptions and selectedValues
        setSelectedOptions((prevOptions) => {
          return {
            ...prevOptions,
            [currentIndex]: selectedOption + 1,
          };
        });

        setSelectedValues((prevValues) => {
          const newValues = [...prevValues];
          newValues[currentIndex] = 1;
          return newValues;
        });
      }
      const answerObj = {
        question_id: questionId,
        choices: selectedOption === null ? [null] : [selectedOption + 1],
      };

      dispatch(setActiveIndex(activeIndex));
      setSeconds(60);
      setSelectedOption([]);
    }
  };

  //.........................................................

  const handleOptionChange = (radioValue) => {
    setSelectedChoices([radioValue]);
    setSelectedOption(radioValue);
    setSelectedOptions((prevOptions) => {
      return {
        ...prevOptions,
        [currentIndex]: radioValue,
      };
    });

    setSelectedValues((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[currentIndex] = 1;
      return newOptions;
    });
  };

  // ...............................................................
  const handleCheckboxChange = (optionValue) => {
    setSelectedChoices([...selectedChoices, optionValue]);
    const options = questionData?.question?.[currentIndex].option;
    const selectedIndex = options?.indexOf(optionValue);
    if (selectedIndex !== -1) {
      setSelectedOption(selectedIndex);

      setSelectedOptions((prevOptions) => {
        const newOptions = { ...prevOptions };
        if (!newOptions[currentIndex]) {
          newOptions[currentIndex] = [];
        }
        const selectedOptions = newOptions[currentIndex];
        const index = selectedOptions.indexOf(optionValue);

        if (index === -1) {
          newOptions[currentIndex] = [...selectedOptions, optionValue];
        } else {
          newOptions[currentIndex] = selectedOptions.filter(
            (value) => value !== optionValue
          );
        }

        return newOptions;
      });
    }

    setSelectedValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[currentIndex] = newValues[currentIndex] || [];

      const currentIndexValues = newValues[currentIndex] || [];
      const index = currentIndexValues.indexOf(optionValue);

      if (index === -1) {
        // If not selected, add to the selected values
        newValues[currentIndex].push(optionValue);
      } else {
        // If already selected, remove from the selected values
        newValues[currentIndex].splice(index, 1);
      }

      return newValues;
    });
  };

  // .....................................................

  const handleSubmit = async () => {
    setAnsArray([
      ...ansArray,
      {
        question_id: quesId,
        choices: selectedChoices.length === 0 ? [""] : selectedChoices,
      },
    ]);

    const totalAns = [
      ...ansArray,
      {
        question_id: quesId,
        choices: selectedChoices.length === 0 ? [""] : selectedChoices,
      },
    ];
    setSelectedChoices([]);

    try {
      if (ansArray.length === currentIndex) {
        setLoading(true);
        let status = 1;
        if (maximizeCount >= 3) {
          status = 3;
        }
        if (
          currentIndex === questionData?.question?.length - 1 &&
          seconds <= 1
        ) {
          status = 2;
        }
        const response = await AssementSubmit.testSubmit(assessmentId, {
          status: status,
          data: totalAns,
          assessmentId,
        });
        setResponse(response);
        if (response === null) {
          navigate("/internship");
        }
      }
      // setSubmitted(true);
      localStorage.setItem("IS_SUBMITTED", true);
      setAnsArray([]);
      setSelectedValues([]);
      setQuestionData([]);
      setSec(0);
      setSeconds(0);
      localStorage.removeItem("TOTAL_TIME");
      localStorage.removeItem("TOTAL_SECONDS");
      localStorage.removeItem("submitValues");
      localStorage.removeItem("questionState");
      localStorage.removeItem("activeIndex");
      localStorage.removeItem("selectedValues");
      localStorage.removeItem("DATA");
      localStorage.removeItem("LOGMESSAGES");
      localStorage.removeItem("MAXIMIZECOUNT");
      localStorage.removeItem("ASSESSMENT_ID");
      // localStorage.removeItem("IS_SUBMITTED");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  //.....................
  useEffect(() => {
    const isSubmitted = localStorage.getItem("IS_SUBMITTED");
    if (
      questionData.length === 0 &&
      isSubmitted === "true" &&
      response === null
    ) {
      navigate("/internship");
    }
  }, [navigate, questionData, response]);
  //........................

  const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme, checked }) => ({
    ".MuiFormControlLabel-label": checked && {
      color: theme.palette.primary.main,
    },
  }));

  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
  }

  //......................................................

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    localStorage.setItem("questionState", JSON.stringify({ seconds }));

    return () => clearInterval(timer);
  }, [seconds]);
  //.........................................................
  useEffect(() => {
    const time = setInterval(() => {
      setSec((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    localStorage.setItem("timerState", JSON.stringify({ sec }));
    return () => clearInterval(time);
  }, [sec]);
  //...................
  const disableRightClick = (event) => {
    event.preventDefault();
  };
  const handleWindowBlur = () => {
    setMaximizeCount((prevCount) => {
      const newCount = prevCount + 1;

      if (newCount !== prevCount) {
        const newMessage = `Don't switch the tab otherwise you will be out of exam: ${newCount}`;
        setLogMessages((prevMessages) => [...prevMessages, newMessage]);
      }

      return newCount; // Return the new state value
    });

    handleOpenConfirmationModal("warning");
    setModalMessage(
      <div style={{ fontSize: 14, color: "red" }}>
        Tab switching detected!{" "}
        <strong style={{ fontWeight: "bold", fontSize: 20 }}>
          {maximizeCount + 1}
        </strong>{" "}
        times, Ensure you stay on this tab to avoid any issues with your exam
        submission.
      </div>
    );
  };

  const handleContextMenu = (event) => {
    disableRightClick(event);
  };
  //.........................
  const mxCnt = maximizeCount >= 3;

  useEffect(() => {
    const addEventListeners = () => {
      window.addEventListener("blur", handleWindowBlur);
      document.addEventListener("contextmenu", handleContextMenu);

      return () => {
        document.removeEventListener("contextmenu", handleContextMenu);
        window.removeEventListener("blur", handleWindowBlur);
      };
    };

    if (savedData?.length !== 0) {
      addEventListeners();
      if (maximizeCount === 3) {
        handleSubmit();
      }

      return () => {
        // Clean up function
        document.removeEventListener("contextmenu", handleContextMenu);
        window.removeEventListener("blur", handleWindowBlur);
      };
    }
  }, [mxCnt, savedData]);

  //...........................
  useEffect(() => {
    localStorage.setItem("MAXIMIZECOUNT", JSON.stringify(maximizeCount));
    localStorage.setItem("LOGMESSAGES", JSON.stringify(logMessages));
  }, [maximizeCount, logMessages]);
  //.........................
  // Somewhere in your code where you want to exit fullscreen
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document
        .exitFullscreen()
        .then(() => {})
        .catch((error) => {
          console.error("Error exiting fullscreen:", error);
        });
    } else {
      console.warn("Document is not in fullscreen mode");
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {};

    if (savedData?.length !== 0) {
      document.addEventListener("fullscreenchange", handleFullscreenChange);
    }
    return () => {
      if (submitted === true) {
        exitFullscreen();
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange
        );
      }
    };
  }, [submitted]);
  //............................
  const handleOpenConfirmationModal = (action) => {
    setCurrentAction(action);
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    const handleFullscreenChange = () => {};
    document.addEventListener("fullscreenchange", handleFullscreenChange);
  };

  const handleConfirmAction = () => {
    if (currentAction === "warning") {
    } else if (currentAction === "save") {
    }

    handleCloseConfirmationModal();
  };
  //.........................................

  return (
    <div className="">
      {submitted ? (
        loading ? (
          <div className="flex items-center justify-center h-screen">
            <InfinitySpin
              visible={true}
              width="200"
              color="#4fa94d"
              ariaLabel="infinity-spin-loading"
            />
          </div>
        ) : response ? (
          <ResponseComponent
            response={response}
            assessment={questionData}
            name={questionData?.detail?.name}
          />
        ) : (
          navigate("/assessment")
        )
      ) : loading || questionData?.length === 0 ? (
        <div className="flex items-center justify-center h-screen">
          <InfinitySpin
            visible={true}
            width="200"
            color="#4fa94d"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      ) : (
        <div className=" w-full shadow-sm">
          <div className="flex flex-wrap justify-between h-14 items-center ">
            <div className="w-full md:w-1/5 pl-10 mb-2 md:mb-0 md:flex-shrink-0 ">
              <img
                src={theme.palette.mode === "dark" ? LogoLight : LogoDark}
                alt="Logo"
                className="h-10 w-full md:w-32 object-contain"
              />
            </div>
            <div className="w-full md:w-3/5 md:flex-shrink-0">
              <h2 className=" font-bold mt-1 text-lg">
                Assessment : {questionData?.detail?.assessment}
              </h2>
            </div>
            <div className="w-full md:w-1/5 pl-2 md:flex-shrink-0">
              <h2 className="font-semibold text-base">
                Exam Date: <span className="pl-1">{todayDate}</span>
              </h2>
              <h2 className="font-semibold text-base">
                Exam Time: <span className="pl-1">{TotalTime(sec)}</span>
              </h2>
            </div>
          </div>
          <div className=" flex w-full fixed h-screen">
            <div className="side w-1/5 pl-7 pr-2 shadow-lg md:h-screen">
              <Questions ansArray={ansArray} />
            </div>
            <div className=" w-3/5">
              <div className=" flex justify-between border-b-2 pt-3 ml-10 w-11/12">
                <div className="font-bold text-lg ml-4 mt-2 h-10">
                  <h2 className="">
                    Name :
                    <span className=" pl-1">{questionData?.detail?.name}</span>
                  </h2>
                </div>
                <div className=" mr-10 pt-2 text-lg font-bold">
                  Remaining Time : {formatTime(seconds)}
                </div>
              </div>
              <div className=" w-11/12 h-[400px] ml-10">
                <div>
                  <LinearProgress
                    color={
                      progress < 30
                        ? "success"
                        : progress < 70
                        ? "warning"
                        : "error"
                    }
                    variant="determinate"
                    value={progress}
                    sx={{ height: 6 }}
                  />
                </div>
                <div className=" rounded-sm p-5 mt-2">
                  {currentIndex !== null &&
                    currentIndex >= 0 &&
                    currentIndex < questionData?.question?.length && (
                      <div>
                        <Typography variant="h3" gutterBottom>
                          {currentIndex + 1}.
                          {questionData?.question?.[currentIndex].question}
                        </Typography>
                        {mcqtype === "2" ? (
                          <FormGroup>
                            {questionData?.question?.[
                              currentIndex
                            ].options?.map((option, index) => (
                              <FormControlLabel
                                key={option.option_id}
                                control={
                                  <Checkbox
                                    checked={
                                      selectedOptions[currentIndex]?.includes(
                                        option.option_id
                                      ) || false
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(option.option_id)
                                    }
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                      marginLeft: "0px",
                                      marginRight: "10px",
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {option.text}
                                  </Typography>
                                }
                                sx={{ marginBottom: "8px" }}
                              />
                            ))}
                          </FormGroup>
                        ) : (
                          <RadioGroup
                            name="use-radio-group"
                            value={
                              selectedOptions[currentIndex] !== undefined
                                ? selectedOptions[currentIndex]
                                : null
                            }
                            onChange={(event) =>
                              handleOptionChange(event.target.value)
                            }
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              marginLeft: "0px",
                              marginRight: "10px",
                            }}
                          >
                            {questionData?.question?.[
                              currentIndex
                            ].options?.map((option, index) => (
                              <MyFormControlLabel
                                value={option.option_id}
                                label={
                                  <Typography
                                    variant="h4"
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    {option.text}
                                  </Typography>
                                }
                                sx={{ marginBottom: "8px" }}
                                index={index}
                                key={index}
                                control={<Radio />}
                              />
                            ))}
                          </RadioGroup>
                        )}

                        <div className=" mt-[15%]">
                          {isLastQuestion ? (
                            <div className=" ml-[86%] ">
                              <Button
                                color="info"
                                variant="contained"
                                size="large"
                                sx={{
                                  borderRadius: "6px",
                                  "&:disabled": {
                                    bgcolor: "green",
                                    color: "white",
                                  },
                                }}
                                onClick={handleSubmit}
                                disabled={selectedOption?.length === 0}
                              >
                                Submit
                              </Button>
                            </div>
                          ) : (
                            <div className="ml-[86%]">
                              <Button
                                color="info"
                                variant="contained"
                                size="large"
                                sx={{
                                  borderRadius: "6px",
                                  "&:disabled": {
                                    bgcolor: "green",
                                    color: "white",
                                  },
                                }}
                                onClick={handleNextClick}
                                disabled={selectedOption?.length === 0}
                              >
                                Next
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="h-screen w-1/5 ml-2">
              <div className="ml-1 mr-1 shadow-md h-screen">
                <h2 className="text-center font-bold tracking-wide text-xl pt-5">
                  Instruction
                </h2>
                <div className="mt-5 text-justify p-3">
                  <h3 className="text-sm text-red-400 font-semibold">
                    Please don't move.focus your face on camera
                  </h3>
                  {logMessages.map((message, index) => (
                    <div
                      key={index}
                      className="text-sm text-red-400 font-semibold"
                    >
                      {message}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CommonModal
        open={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleConfirmAction}
        action={currentAction}
        msg={modalMessage}
      />
    </div>
  );
};

export default Assement;
