import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const CustomError = ({ message }) => {
  const navigate = useNavigate();
  return (
    <div className=" flex flex-col gap-5 items-center justify-center h-[620px]">
      <Helmet>
        <title> EduSkills | Error 404 </title>
      </Helmet>
      <div className="">
        <h2 className=" text-center text-xl text-red-600 font-bold">
          Oh no! Error <span>404</span>
        </h2>
        <h3 className=" text-center font-semibold italic">{message}</h3>
      </div>
      <button
        className=" rounded-md bg-sky-700 text-white px-4 py-2 hover:scale-105 text-base"
        onClick={() => navigate("/")}
      >
        Go Home
      </button>
    </div>
  );
};

export default CustomError;
