import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Popover from "@mui/material/Popover";
import DescriptionIcon from "@mui/icons-material/Description";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Checkbox, useMediaQuery, useTheme } from "@mui/material";
import { getOngoingInternships } from "../../services/dataService";
import genpdf from "../../assets/Google GenAI.pdf";

const defaultTheme = createTheme();

export default function SignIn() {
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [cohortId, setCohortId] = React.useState(0);
  const [domainId, setDomainId] = React.useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    width: isSmallScreen ? "90%" : "70%",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [emailPopoverAnchor, setEmailPopoverAnchor] = React.useState(null);
  const [urlPopoverAnchor, setUrlPopoverAnchor] = React.useState(null);

  const handleClose = () => setOpen(false);

  const handleEmailClick = (event) => {
    setEmailPopoverAnchor(event.currentTarget);
  };

  const handleUrlClick = (event) => {
    setUrlPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setEmailPopoverAnchor(null);
    setUrlPopoverAnchor(null);
  };

  React.useEffect(() => {
    const fetchOngoingInternshipData = async () => {
      try {
        const response = await getOngoingInternships.ongoingInternshipsData();
        if (response.data && response.data.internships) {
          const data = response.data.internships;
          setCohortId(data[0].cohort_id);
          setDomainId(data[0].domain_id);
          console.log("ongoing", data);
          console.log(data[0].cohort_id, "cohortId");
          console.log(domainId);
        } else {
          throw new Error("Invalid response structure");
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchOngoingInternshipData();

    const modalShown = localStorage.getItem("modalShown");
    if (!modalShown) {
      setOpen(true);
      localStorage.setItem("modalShown", "true");
    }
  }, []);

  const onSubmit = async (data) => {
    const token = localStorage.getItem("accessToken");
    console.log(token);
    const payload = {
      domain_id: domainId,
      cohort_id: cohortId,
      url: data.url,
    };

    try {
      const response = await fetch(
        "https://erpapi.eduskillsfoundation.org/internship/add-internships",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        toast.success("Form submitted successfully!");
        window.location.reload();
      } else {
        toast.error("There was an error submitting the form.");
      }
    } catch (error) {
      toast.error("There was an error submitting the form.");
    }
  };

  const onError = (errors) => {
    toast.error("There was an error submitting the form.");
  };

  const openUrlPopover = Boolean(urlPopoverAnchor);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Card sx={{ marginTop: 8, boxShadow: 3 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Gen AI Lab Request Form
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit, onError)}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="url"
                  label="URL"
                  name="url"
                  type="text"
                  autoComplete="current-password"
                  onClick={handleUrlClick}
                  {...register("url", {
                    required: "URL is required",
                    pattern: {
                      value:
                        /^https:\/\/www\.cloudskillsboost\.google\/public_profiles\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
                      message: "Invalid URL format",
                    },
                  })}
                  error={Boolean(errors.url)}
                  helperText={errors.url?.message}
                />
                <Popover
                  id="url-popover"
                  open={openUrlPopover}
                  anchorEl={urlPopoverAnchor}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography sx={{ p: 2 }}>
                    The URL should be your Google Skill Boost Public profile URL
                  </Typography>
                </Popover>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Typography variant="h6" component="h2" align="center">
              Gen AI Lab Request Notice
            </Typography>
            <Typography sx={{ mt: 2 }}>Instructions:</Typography>
            <Box sx={{ mt: 2, ml: 2 }}>
              <Typography component="div" sx={{ mt: 1 }}>
                • Read and understand the process document before proceeding
                further.{" "}
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  component={Link}
                  href={genpdf}
                  target="_blank"
                  rel="noopener"
                  sx={{
                    textTransform: "none",
                    ml: 1,
                  }}
                >
                  <DescriptionIcon sx={{ mr: 1 }} />
                  View Document
                </Button>
              </Typography>
              <Typography component="div" sx={{ mt: 1 }}>
                • Note 2: Create cloud skill boost account{" "}
                <span style={{ fontWeight: "bold" }}>
                  (using Gen AI Internship registered email Id only).
                </span>
              </Typography>
              <Typography component="div" sx={{ mt: 1 }}>
                • Note 3: Make your profile public.
              </Typography>
              <Typography component="div" sx={{ mt: 1 }}>
                • Note 4: Submit the public profile link (url) to complete the
                process.
              </Typography>
            </Box>
            <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                color="primary"
              />
              <Typography variant="body2">
                I acknowledge that I have read all the processes.
              </Typography>
            </Box>
            <Button
              onClick={handleClose}
              fullWidth
              disabled={!checked}
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
            >
              Proceed
            </Button>
          </Box>
        </Modal>

        <ToastContainer />
      </Container>
    </ThemeProvider>
  );
}
