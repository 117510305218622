import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const ObjectiveForm = ({
  onNext,
  onError,
  onComplete,
  handleBack,
  initialData,
}) => {
  const [objectiveInput, setObjectiveInput] = useState("");
  const [error, setError] = useState();

  useEffect(() => {
    // console.log("initialData:", initialData);
    const storedData = localStorage.getItem("careerObjective");

    if (storedData) {
      // console.log("Setting careerObjective from localStorage:", storedData);
      setObjectiveInput(storedData);
    } else {
      // console.log("Setting skills from initialData:", initialData?.objective);
      setObjectiveInput(initialData ? initialData?.objective : "");
    }
  }, [initialData]);
  useEffect(() => {
    // Check if career objective is stored in local storage on component mount
    const storedObjective = localStorage.getItem("careerObjective");
    if (storedObjective) {
      setObjectiveInput(storedObjective);
    }
  }, []);
  const handleNextClick = () => {
    if (!objectiveInput || objectiveInput?.trim() === "") {
      setError("Please enter your career objective");
    } else {
      const wordCount = objectiveInput.trim().split(/\s+/).length;

      if (wordCount > 50) {
        setError("Your career objective must not exceed 100 words");
      } else {
        setError(null);
        localStorage.setItem("careerObjective", objectiveInput);
        onNext(objectiveInput);
      }
    }
  };

  return (
    <div>
      <div className="p-2 md:p-2 lg:p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
        <div className="mb-4 col-span-2">
          <label
            htmlFor="careerObjective"
            className="block text-gray-700 text-sm font-bold"
          >
            Career Objective
          </label>
          <textarea
            id="careerObjective"
            placeholder="Enter your career objective"
            value={objectiveInput}
            onChange={(e) => setObjectiveInput(e.target.value)}
            required
            className={`border-2 text-gray-950 mt-1 pl-1 rounded-md bg-gray-100 w-full h-32 focus:outline-sky-400 ${
              error ? "border-red-500" : ""
            }`}
          />
          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
      <div className="left-0 w-full flex justify-between">
        <div className="ml-4 flex justify-start md:absolute md:bottom-5 mb-5">
          <Button
            className="mt-4"
            variant="contained"
            color="success"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        <div className="mr-2 flex justify-end md:absolute md:bottom-5 mb-5 md:right-12">
          <Button
            className="mt-4"
            variant="contained"
            color="secondary"
            onClick={handleNextClick}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ObjectiveForm;
