import api from "./api";
import axios from "axios";
import { BASE_URL, API_URLS } from "./configUrls";
// import { BASE_URL1, API_URLS1 } from "./configUrls";

// //base url for sm project................................
// export const testService = axios.create({
//   baseURL: BASE_URL1,
// });
//.........................
// export const AuthService={
// login: async (email, password) => {
//   try {
//     const response = await dataService.post(API_URLS1.LOGIN, {
//       email,
//       password,
//     });
//     console.log("response through email and password", response);
//     return response; // Optionally return response data
//   } catch (error) {
//     throw error;
//     // handleServiceError(error, "Login failed");
//   }
// },
//   logout: async () => {
//     try {
//       const response = await api.post(API_URLS1.LOGOUT, {
//         refresh_token: localStorage.getItem("refreshToken"),
//       });
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
//   roles: async () => {
//     try {
//       const response = await api.get(API_URLS.ROLES);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
//   switch_role: async (id) => {
//     try {
//       const response = await api.put(`${API_URLS.SWITCH_ROLE}${id}`);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
// }
// export const AssementSubmit = {
//   testSubmit: async (id, data) => {
//     try {
//       const response = await api.post(
//         `${API_URLS1.ASSESSMENT_SUBMIT}${id}`,
//         data
//       );
//       return response;
//     } catch (error) {
//       throw error;
//       // handleServiceError(error, "assessment submition  failed");
//     }
//   },
// };
// export const instructionService = {
//   instruction: async () => {
//     try {
//       const response = await api.get(API_URLS1.INSTRUCTION_DATA);
//       return response; // Optionally return response data
//     } catch (error) {
//       throw error;
//       // handleServiceError(error, "Login failed");
//     }
//   },
// };
// //...............talent connect page

// //...........list of jobs data
// export const applyingJobService = {
//   jobApply: async () => {
//     try {
//       const response = await api.get(API_URLS1.APPLYING_JOB);
//       return response;
//     } catch (error) {
//       throw error;
//     }
//   },
// };

//.........................

const dataService = axios.create({
  baseURL: BASE_URL,
});

// // AuthService --------------------------------------------------

export const AuthService = {
  //...........
  // verifyOtp: async (email, otp, institute) => {
  //   try {
  //     const response = await dataService.post(API_URLS.VERIFY_OTP_PROFILE, {
  //       email: email,
  //       otp: otp,
  //       institute: institute,
  //     });
  //     return response; // Optionally return response data
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  //............
  login: async (email, password) => {
    try {
      // console.log("Hello");
      const response = await dataService.post(API_URLS.LOGIN, {
        email,
        password,
      });
      // console.log("response through email and password", response);
      return response; // Optionally return response data
    } catch (error) {
      console.log("error reason", error);
      throw error;
      // handleServiceError(error, "Login failed");
    }
  },
  logout: async () => {
    try {
      const response = await api.post(API_URLS.LOGOUT, {
        refresh_token: localStorage.getItem("refreshToken"),
      });
      console.log("response from logout", response);
      return response;
    } catch (error) {
      throw error;
    }
  },
  sendOtp: async (otpData) => {
    try {
      const response = await dataService.post(API_URLS.SEND_OTP, otpData);
      return response; // Optionally return response data
    } catch (error) {
      throw error;
    }
  },
  verifyOtp: async (email, otp) => {
    try {
      const response = await dataService.post(API_URLS.VERIFY_OTP, {
        email: email,
        otp: otp,
      });
      return response; // Optionally return response data
    } catch (error) {
      throw error;
    }
  },
  roles: async () => {
    try {
      const response = await api.get(API_URLS.ROLES);
      return response;
    } catch (error) {
      throw error;
    }
  },
  switch_role: async (id) => {
    try {
      const response = await api.put(`${API_URLS.SWITCH_ROLE}${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export const AssementService = {
  question: async (id) => {
    try {
      const response = await api.get(`${API_URLS.ASSESSMENT_QUES}${id}`);
      return response; // Optionally return response data
    } catch (error) {
      throw error;
      // handleServiceError(error, "Login failed");
    }
  },
};

export const AssementSubmit = {
  testSubmit: async (id, data) => {
    try {
      const response = await api.post(
        `${API_URLS.ASSESSMENT_SUBMIT}${id}`,
        data
      );
      return response;
    } catch (error) {
      throw error;
      // handleServiceError(error, "assessment submition  failed");
    }
  },
};
export const instructionService = {
  instruction: async () => {
    try {
      const response = await api.get(API_URLS.INSTRUCTION_DATA);
      return response; // Optionally return response data
    } catch (error) {
      throw error;
      // handleServiceError(error, "Login failed");
    }
  },
};
//...............talent connect page

//...........list of jobs data
export const applyingJobService = {
  jobApply: async () => {
    try {
      const response = await api.get(API_URLS.APPLYING_JOB);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
//...........Jobs details page
export const JobDescriptionService = {
  jobDescription: async (id) => {
    try {
      const response = await api.get(`${API_URLS.JOB_DETAILS}${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

//......initial load data if data have of that user
export const initialLoadDataService = {
  initialLoadData: async () => {
    try {
      const response = await api.get(API_URLS.INITIAL_DATA);
      console.log("response", response);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
//............Resume apply
export const submitJobService = {
  submittedJobData: async (data) => {
    console.log(data);
    // console.log(data, "hete is submit job");
    try {
      const response = await api.post(`${API_URLS.JOB_SUBMIT}`, data);

      return response;
    } catch (error) {
      console.log("error where?", error);
    }
  },
};
//.......................
export const submitResumeService = {
  submittedResumeData: async (data) => {
    // console.log(data, "here resume data will submit");
    // console.log(data);
    try {
      const response = await api.post(`${API_URLS.RESUME_SUBMIT}`, data);
      return response;
    } catch (error) {
      console.log("error where?", error);
    }
  },
};
//...........list of applied jobs for student
export const appliedJobService = {
  appliedJobData: async () => {
    try {
      const response = await api.get(API_URLS.APPLIED_JOB);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
};
//............Applied Job Details
export const appliedJobDetailsService = {
  appliedJobDetails: async (id) => {
    // console.log(id, "here is id");
    try {
      const response = await api.get(`${API_URLS.APPLIED_JOB_DETAILS}${id}`);
      // console.log(response);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export const profileUpdateDataService = {
  updateProfile: async (data) => {
    console.log("profileData", data);
    try {
      const response = await api.put(`${API_URLS.PROFILE_UPDATE}`, data);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
};

//Export the data service if needed
// export default dataService;
//profile update
export const profileGetDataService = {
  profileData: async () => {
    try {
      const response = await api.get(`${API_URLS.PROFILE_DATA}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
//states list
export const getStates = {
  statesData: async () => {
    try {
      const response = await api.get(`${API_URLS.GET_STATES}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export const getCompletedInternships = {
  internshipsData: async () => {
    try {
      const response = await api.get(`${API_URLS.COMPLETED_INTERNSHIPS}`);
      console.log(response, "Completed InternShips");
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export const getOngoingInternships = {
  ongoingInternshipsData: async () => {
    try {
      const response = await api.get(`${API_URLS.ONGOING_INTERNSHIPS}`);
      console.log(response, "Ongoing InternShips");
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export const getPrograms = {
  programsData: async () => {
    try {
      const response = await api.get(`${API_URLS.GET_PROGRAM}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export const getInstitutes = {
  institutesData: async () => {
    try {
      const response = await api.get(`${API_URLS.GET_INSTITUTE}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export const getBranches = {
  institutesData: async () => {
    try {
      const response = await api.get(`${API_URLS.GET_BRANCH}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export const getInternshipCourses = {
  coursesData: async () => {
    try {
      const response = await api.get(`${API_URLS.COURSES}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export const getInternshipCertificate = {
  getLongTermCertificate: async () => {
    try {
      const response = await api.get(`${API_URLS.GET_CERTIFICATE}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export const getInternshipShortCertificate = {
  getShortTermCertficate: async () => {
    try {
      const response = await api.get(`${API_URLS.GET_SHORT_CERTIFICATE}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export const getInternshipBranches = {
  branchesData: async (courseId) => {
    try {
      const response = await api.get(`${API_URLS.BRANCHES}/${courseId}`);
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export const getInternshipCourseDetails = {
  coursesDetails: async () => {
    try {
      const response = await api.get(`${API_URLS.INTERNSHIPS_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export const getInternshipCertificateDetails = {
  certificateDetails: async () => {
    try {
      const response = await api.get(`${API_URLS.GET_INTERNSHIP_DOMAIN}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export const getDomainChoose = {
  domainChoose: async () => {
    try {
      const response = await api.get(`${API_URLS.DOMAIN_CHOOSE}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};
