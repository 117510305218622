import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
const EducationalDetailsForm = ({
  onNext,
  onError,
  handleBack,
  onComplete,
  initialEductionData,
}) => {
  const [educationDetails, setEducationDetails] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    // console.log("initialData:", initialEductionData);
    const storedData = localStorage.getItem("educationData");
    // console.log(storedData);

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        // console.log("Setting educationData from localStorage:", parsedData);
        setEducationDetails(parsedData);
      } catch (error) {
        console.error("Failed to parse stored education data:", error);
      }
    } else {
      // console.log(
      //   "Setting educationData from initialData:",
      //   initialEductionData?.education
      // );
      setEducationDetails(
        initialEductionData ? initialEductionData.education : []
      );
    }
  }, [initialEductionData]);

  useEffect(() => {
    // Save data to local storage whenever educationDetails changes
    localStorage.setItem("educationData", JSON.stringify(educationDetails));

    // Log the updated educationDetails after the state has been updated
    // console.log("Updated Education Details:", educationDetails);
  }, [educationDetails]);

  const handleChange = (index, field, value) => {
    setEducationDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];

      if (field === "otherDegree") {
        // If the field is "otherDegree," update degree to "Other"
        updatedDetails[index] = {
          ...updatedDetails[index],
          degree: "Other",
          otherDegree: value,
        };
      } else {
        // For other fields, update as usual
        updatedDetails[index][field] = value;
      }

      // Validate the input and update errors
      const errors = validateInput(updatedDetails);
      setValidationErrors(errors);

      return updatedDetails;
    });

    // Validate the input and update errors
    setValidationErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = { ...newErrors[index], [field]: false };
      return newErrors;
    });
  };

  const validateInput = (details) => {
    return (details || [])?.map((edu) => ({
      school: edu?.school?.trim() === "",
      degree: edu?.degree?.trim() === "",
      percentage: edu?.percentage?.trim() === "",
      passoutYear: edu?.passoutYear?.trim() === "",
    }));
  };

  const handleAddField = () => {
    const isAnyFieldEmpty = educationDetails?.some(
      (edu) =>
        edu.school.trim() === "" ||
        edu.degree.trim() === "" ||
        edu.percentage.trim() === "" ||
        edu.passoutYear.trim() === ""
    );

    if (!isAnyFieldEmpty) {
      setEducationDetails([
        ...(educationDetails || []),
        { school: "", degree: "", percentage: "", passoutYear: "" },
      ]);

      // Clear validation errors
      setValidationErrors([]);

      // console.log("Added Education Details:", educationDetails);
    } else {
      // Set validation errors for each field
      const errors = validateInput(educationDetails);
      setValidationErrors(errors);
    }
  };
  //........handle next
  const handleNext = () => {
    // Validate each field and check if there are any errors
    const educationDetailsArray = Array.isArray(educationDetails)
      ? educationDetails
      : [];
    const errors = validateInput(educationDetailsArray);
    const hasErrors = errors.some((error) =>
      Object.values(error).some((value) => value)
    );

    if (educationDetailsArray.length < 3) {
      // Display an alert message if educationDetailsArray is empty
      alert("Please fill three education detail.");
    } else if (!hasErrors) {
      // If there are no errors, proceed to the next step
      onNext([...educationDetailsArray]);
      onError(false);
      onComplete();
    } else {
      // If there are errors, set the validation errors and display an error message
      setValidationErrors(errors);
      onError(true);
    }
  };

  const handleDeleteField = (index) => {
    const updatedDetails = [...educationDetails];
    updatedDetails.splice(index, 1);
    setEducationDetails(updatedDetails);

    // console.log("Deleted Education Details:", updatedDetails);
  };
  return (
    <div className="p-5">
      <div className="container mx-auto mt-8">
        <h2 className="text-xl font-semibold mb-4">Education Details Form</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-100 text-gray-950">
                <th className="border border-gray-300 py-2 px-4">
                  School/University
                </th>
                <th className="border border-gray-300 py-2 px-4">Degree</th>
                <th className="border border-gray-300 py-2 px-4">Percentage</th>
                <th className="border border-gray-300 py-2 px-4">
                  Passout Year
                </th>
                <th className="border border-gray-300 py-2 px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {educationDetails?.map((edu, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 py-2 px-4">
                    <input
                      type="text"
                      className={`w-full text-gray-950 bg-gray-100 ${
                        validationErrors[index] &&
                        validationErrors[index].school
                          ? "border-red-500"
                          : ""
                      }`}
                      value={edu.school}
                      onChange={(e) =>
                        handleChange(index, "school", e.target.value)
                      }
                    />
                    {validationErrors[index] &&
                      validationErrors[index].school && (
                        <p className="text-red-500 text-xs">
                          School is required
                        </p>
                      )}
                  </td>
                  <td className="border border-gray-300 py-2 px-4">
                    <input
                      type="text"
                      className={`w-full text-gray-950 bg-gray-100 ${
                        validationErrors[index] &&
                        validationErrors[index].degree
                          ? "border-red-500"
                          : ""
                      }`}
                      value={edu.degree}
                      onChange={(e) =>
                        handleChange(index, "degree", e.target.value)
                      }
                    />
                    {validationErrors[index] &&
                      validationErrors[index].degree && (
                        <p className="text-red-500 text-xs">
                          Degree is required
                        </p>
                      )}
                  </td>
                  <td className="border border-gray-300 py-2 px-4">
                    <input
                      type="text"
                      className={`w-full text-gray-950 bg-gray-100 ${
                        validationErrors[index] &&
                        validationErrors[index].percentage
                          ? "border-red-500"
                          : ""
                      }`}
                      value={edu.percentage}
                      onChange={(e) =>
                        handleChange(index, "percentage", e.target.value)
                      }
                    />
                    {validationErrors[index] &&
                      validationErrors[index].percentage && (
                        <p className="text-red-500 text-xs">
                          Percentage is required
                        </p>
                      )}
                  </td>
                  <td className="border border-gray-300 py-2 px-4">
                    <input
                      type="date"
                      className={`w-full text-gray-950 bg-gray-100 ${
                        validationErrors[index] &&
                        validationErrors[index].passoutYear
                          ? "border-red-500"
                          : ""
                      }`}
                      value={edu.passoutYear}
                      onChange={(e) =>
                        handleChange(index, "passoutYear", e.target.value)
                      }
                    />
                    {validationErrors[index] &&
                      validationErrors[index].passoutYear && (
                        <p className="text-red-500 text-xs">
                          Passout year is required
                        </p>
                      )}
                  </td>
                  <td className="border border-gray-300 py-2 px-4">
                    <button
                      onClick={() => handleDeleteField(index)}
                      className="py-1 px-1 flex rounded-md border-2 border-red-300 gap-1 text-red-400 hover:bg-red-400 hover:text-white"
                    >
                      <Icon
                        icon="bx:bx-trash"
                        className="font-bold"
                        height={20}
                        width={20}
                      />
                      <h2 className="">Delete</h2>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          onClick={handleAddField}
          className="py-1 px-1 flex rounded-md border-2 mt-2 border-green-300 gap-1 text-green-400 hover:bg-green-400 hover:text-white"
        >
          <Icon
            icon="material-symbols:add"
            className="font-bold"
            height={20}
            width={20}
          />
          <h2 className="">Add more</h2>
        </button>
      </div>
      <div className="left-0 mt-10 w-full flex justify-between">
        <div className="ml-4 flex justify-start md:absolute md:bottom-1 mb-5 md:mb-10">
          <Button
            className="mt-4"
            variant="contained"
            color="success"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        <div className="mr-2 flex justify-end md:absolute md:bottom-1 mb-5 md:mb-10 md:right-12">
          <Button
            className="mt-4"
            variant="contained"
            color="secondary"
            onClick={handleNext}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EducationalDetailsForm;
