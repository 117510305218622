import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appliedJobDetailsService } from "../../services/dataService";
import { Oval } from "react-loader-spinner";
import CustomError from "../../components/Error/CustomError";

const AppliedJob = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [appliedJobDetails, setAppliedJobDetails] = useState();
  const JobId = location?.state?.jobId;
  // console.log(JobId, "job id applied job");
  const handleBack = () => {
    navigate("/jobs/applied");
  };
  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setLoading(true);
        // console.log(JobId, "here is job id");
        const response = await appliedJobDetailsService.appliedJobDetails(
          JobId
        );
        setAppliedJobDetails(response?.data?.data);
      } catch (error) {
        // console.log("Error fetching initial data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchJobDetails();
  }, [JobId]);

  return (
    <div className=" m-5 shadow-md rounded-md min-h-10">
      {loading ? (
        <div className="flex items-center justify-center h-screen">
          <Oval
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : error ? (
        <CustomError message={error} />
      ) : (
        <div>
          <div className="text-2xl font-semibold pl-10 pt-5">
            {appliedJobDetails && (
              <div>
                {/* <h2>{appliedJobDetails?.job_title}</h2> */}
                <p className="text-lg mt-2">{appliedJobDetails?.company}</p>
              </div>
            )}
          </div>
          <div className="pl-10">
            <div className="flex justify-end mr-5 pb-5">
              <button
                className="rounded-md px-4 py-2 bg-sky-400 text-white shadow-sm font-semibold"
                onClick={handleBack}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppliedJob;
