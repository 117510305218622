// ErrorBoundary.js
import React from 'react';
import { Button } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  handleRetry = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-red-100 p-6">
          <h1 className="text-2xl font-semibold text-red-600 mb-4">
            Something went wrong!
          </h1>
          <p className="text-red-500 mb-4">
            {this.state.error?.toString() || "An unknown error occurred."}
          </p>
          <Button variant="contained" color="primary" onClick={this.handleRetry}>
            Retry
          </Button>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
