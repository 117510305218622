import React, { useEffect, useState } from "react";
import {
  Card,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  Select,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  CircularProgress,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { blue, red } from "@mui/material/colors";
import axios from "axios";
import {
  getInternshipBranches,
  getInternshipCourseDetails,
  getInternshipCourses,
} from "../../services/dataService";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ShortTextIcon from "@mui/icons-material/ShortText";
import { useNavigate } from "react-router-dom";
import ApscheCertificate from "./ApscheCertificate";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import apschelogo from "../../assets/apschelogo.jpg";
import { BASE_URL } from "../../services/configUrls";

// const StyledCard = styled(Card)(({ theme }) => ({
//   padding: theme.spacing(4),
//   margin: theme.spacing(2),
//   maxWidth: "800px",
//   width: "100%",
//   boxShadow: theme.shadows[3],
//   backgroundColor: "#fff",
// }));

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: blue[700],
    },
  },
});

const StyledSelect = styled(Select)({
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: blue[700],
    },
  },
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: blue[700],
  "&:hover": {
    backgroundColor: blue[800],
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  maxWidth: "800px",
  width: "100%",
  boxShadow: theme.shadows[3],
  backgroundColor: "#fff",
}));

const CertificateCard = styled(Card)(({ theme }) => ({
  width: 200,
  height: 250,
  display: "flex",
  flexDirection: "column",
  position: "relative",
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
  overflow: "hidden",
}));

// const IconPlaceholder = styled("div")(({ theme }) => ({
//   width: 100,
//   height: 100,
//   borderRadius: "10%",
//   //backgroundColor: blue[100],
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   marginBottom: theme.spacing(2),
//   overflow: "hidden",
// }));

const LoadingOverlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
});

const Certificate = () => {
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courses, setCourses] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [rollNo, setRollNo] = useState("");
  // const [confirmCourse, setConfirmCourse] = useState(false);
  // const [confirmBranch, setConfirmBranch] = useState(false);
  // const [confirmRollNo, setConfirmRollNo] = useState(false);
  // const [confirmInstitution, setConfirmInstitution] = useState(true);
  // const [confirmUniversity, setConfirmUniversity] = useState(true);
  // const [confirmInternship, setConfirmInternship] = useState(true);
  // const [confirmName, setConfirmName] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  //const [confirmSemester, setConfirmSemester] = useState(true);

  const [name, setName] = useState("");
  const [semesterName, setSemesterName] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [universityName, setUniversityName] = useState("");
  const [internshipCourse, setInternshipCourse] = useState("");
  //const [domain, setDomain] = useState("");
  const [semesters, setSemesters] = useState([]);
  const [longTerm, setLongTerm] = useState("0");
  const [shortTerm, setShortTerm] = useState("0");
  const [isApscheStatus, setIsApscheStatus] = useState(1);
  const [isMainStatus, setIsMainStatus] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  //const [formStatus, setFormStatus] = useState("initial");
  const [longTermApplied, setLongTermApplied] = useState(false);
  const [shortTermApplied, setShortTermApplied] = useState(false);
  const [coursesDetails, setCourseDetails] = useState([]);
  const [eligibleLongTerm, setEligibleLongTerm] = useState(0);
  const [isLongTermApplied, setIsLongTermApplied] = useState(0); // Assuming you get this from API response
  const [showEligibilityDialog, setShowEligibilityDialog] = useState(false);

  const certificateTypes = [
    {
      type: "apsche",
      label: "Apsche Certificate",
      icon: apschelogo,
    },
  ];

  useEffect(() => {
    // axios
    //   .get("http://192.168.1.105:8000/internship/courseDropdown")
    getInternshipCourses
      .coursesData()
      .then((response) => {
        setCourses(response?.data);
      })
      .catch((error) => {
        setSnackbarMessage("Failed to load courses");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
    // axios
    //   .get("http://192.168.1.105:8000/internship/getDetails"
    //     , {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    //     },
    //   })
    getInternshipCourseDetails
      .coursesDetails()
      .then((response) => {
        const {
          name,
          institute_name,
          university_name,
          course,
          branch,
          is_apsche_status,
          is_apsche_status_,
          is_eligible_for_longterm,
          roll_no,
          domain,
        } = response.data;
        console.log(response);
        setCourseDetails(response.data);
        setName(name);
        setRollNo(roll_no);
        setInstitutionName(institute_name);
        setInternshipCourse(domain);
        setUniversityName(university_name);
        setIsApscheStatus(is_apsche_status);
        setIsMainStatus(is_apsche_status_);
        setLongTermApplied(is_apsche_status === 2);
        setShortTermApplied(is_apsche_status_ === 2);
        setEligibleLongTerm(is_eligible_for_longterm);
        setIsLoading(false);
      })
      .catch((error) => {
        setSnackbarMessage("Failed to load details");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setIsLoading(false);
      });
  }, []);

  const handleCourseChange = (event) => {
    const courseId = event.target.value;
    const selectedCourseData = courses.find((c) => c.program_id === courseId);
    setSelectedCourse(selectedCourseData);
    setSelectedBranch("");
    setSemesterName("");

    // axios
    //   .get(`http://192.168.1.105:8000/internship/branchDropdown/${courseId}`)
    getInternshipBranches
      .branchesData(courseId)
      .then((response) => {
        console.log(response);
        setBranches(response?.data);
      })
      .catch((error) => {
        setSnackbarMessage("Failed to load branches");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });

    axios
      .get(`${BASE_URL}/internship/semesterDropdown/${courseId}`)
      .then((response) => {
        setSemesters(response?.data);
        console.log(response?.data);
      })
      .catch((error) => {
        setSnackbarMessage("Failed to load semesters");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      rollNo &&
      selectedCourse &&
      selectedBranch &&
      semesterName &&
      longTerm
    ) {
      setIsLoading(true); // Start loading

      const formData = {
        name,
        instituteName: institutionName,
        universityName: universityName,
        program_id: selectedCourse.program_id,
        branch: selectedBranch,
        semester: semesterName,
        rollNo,
        longterm: isApscheStatus !== 2 && longTerm,
        shortterm: isMainStatus !== 2 && shortTerm,
      };

      axios
        .put(`${BASE_URL}/internship/updateDetails`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
          },
        })
        .then((response) => {
          console.log(response);

          // Update the status immediately after successful submission
          setIsApscheStatus(2);
          //setFormStatus("submitted");
          setSnackbarMessage("Form submitted successfully!");
          setSnackbarSeverity("success");
          setLongTermApplied(longTerm === "1" || longTermApplied);
          setShortTermApplied(shortTerm === "1" || shortTermApplied);
          setSnackbarOpen(true);
          handleCloseDialog();
        })
        .catch((error) => {
          setSnackbarMessage("Error submitting form.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        })
        .finally(() => {
          setIsLoading(false); // Stop loading
        });
    } else {
      setSnackbarMessage(
        "Please fill out all required fields before submitting."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    // Show dialog based on eligibility when the user reaches the page
    if (isLongTermApplied === 0) {
      setShowEligibilityDialog(true);
    } else if (isLongTermApplied === 1) {
      setShowEligibilityDialog(true);
    }
  }, [isLongTermApplied]);

  const handleCloseEligibilityDialog = () => {
    setShowEligibilityDialog(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOpenDialog = (certificateType) => {
    setSelectedCertificate(certificateType);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedCertificate(null);
  };

  const handleLongTermChange = (event) => {
    setLongTerm(event.target.value);
  };
  const handleShortTermChange = (event) => {
    setShortTerm(event.target.value);
  };

  const renderForm = () => {
    if (isLoading) {
      return (
        <LoadingOverlay>
          <CircularProgress size={60} thickness={4} />
        </LoadingOverlay>
      );
    }

    if (Array.isArray(coursesDetails) && coursesDetails.length === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Typography variant="h5" color="error">
            No certificate found
          </Typography>
        </Box>
      );
    }
    switch (selectedCertificate) {
      case "apsche":
        return (
          <form className="space-y-6" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div className="flex items-center space-x-4">
                  <StyledTextField
                    label="Internship Course"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={internshipCourse}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex items-center space-x-4">
                  <StyledTextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={name}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex items-center space-x-4">
                  <StyledTextField
                    label="Institution Name"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={institutionName}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex items-center space-x-4">
                  <StyledTextField
                    label="University Name"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    value={universityName}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex items-center space-x-4">
                  <StyledTextField
                    label="Roll No"
                    variant="outlined"
                    fullWidth
                    value={rollNo}
                    onChange={(e) => setRollNo(e.target.value)}
                    error={!rollNo}
                    helperText={!rollNo ? "Roll No is required" : ""}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex items-center space-x-4">
                  <StyledSelect
                    value={selectedCourse.program_id || ""}
                    onChange={handleCourseChange}
                    displayEmpty
                    fullWidth
                    variant="outlined"
                    error={!selectedCourse}
                  >
                    <MenuItem value="" disabled>
                      Select Course
                    </MenuItem>
                    {courses.map((course) => (
                      <MenuItem
                        key={course.program_id}
                        value={course.program_id}
                      >
                        {course.program_name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex items-center space-x-4">
                  <StyledSelect
                    value={selectedBranch}
                    onChange={(e) => setSelectedBranch(e.target.value)}
                    displayEmpty
                    fullWidth
                    variant="outlined"
                    error={!selectedBranch}
                  >
                    <MenuItem value="" disabled>
                      Select Branch
                    </MenuItem>
                    {branches.map((branch) => (
                      <MenuItem
                        key={branch.branch_id}
                        value={branch.branch_name}
                      >
                        {branch.branch_name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="flex items-center space-x-4">
                  <StyledSelect
                    value={semesterName}
                    onChange={(e) => setSemesterName(e.target.value)}
                    displayEmpty
                    fullWidth
                    variant="outlined"
                    error={!semesterName}
                  >
                    <MenuItem value="">Select Semester</MenuItem>
                    {semesters.map((semester, index) => (
                      <MenuItem key={index} value={semester}>
                        {semester}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel>
                    {isApscheStatus === 4 ? (
                      <Typography variant="body1" style={{ display: "flex" }}>
                        <CancelOutlinedIcon
                          style={{ color: "red", marginLeft: "12px" }}
                        />
                        Your Long-Term Certificate is Rejected.
                      </Typography>
                    ) : isApscheStatus === 3 ? (
                      <Typography variant="body1" style={{ display: "flex" }}>
                        <CheckCircleIcon
                          style={{ color: "green", marginLeft: "12px" }}
                        />
                        Your Long-Term Certificate is Approved.
                      </Typography>
                    ) : longTermApplied ? (
                      <Typography variant="body1" style={{ display: "flex" }}>
                        <CheckCircleIcon
                          style={{ color: "green", marginLeft: "12px" }}
                        />
                        Already Applied For Long-Term. Wait for the Spoc
                        Approval.
                      </Typography>
                    ) : (
                      eligibleLongTerm !== 0 &&
                      "Are You Applying for Long-Term Certificate?"
                    )}
                  </FormLabel>
                  {!longTermApplied &&
                    isApscheStatus !== 4 &&
                    isApscheStatus !== 3 &&
                    eligibleLongTerm !== 0 && (
                      <RadioGroup
                        row
                        value={longTerm}
                        onChange={handleLongTermChange}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel>
                    {isMainStatus === 4 ? (
                      <Typography
                        variant="body1"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <CancelOutlinedIcon
                          style={{ color: "red", marginLeft: "8px" }}
                        />
                        Your Short-Term Certificate is Rejected.
                      </Typography>
                    ) : isMainStatus === 3 ? (
                      <Typography
                        variant="body1"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <CheckCircleIcon
                          style={{ color: "green", marginLeft: "8px" }}
                        />
                        Your Short-Term Certificate is Approved.
                      </Typography>
                    ) : shortTermApplied ? (
                      <Typography
                        variant="body1"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <CheckCircleIcon
                          style={{ color: "green", marginLeft: "8px" }}
                        />
                        Already Applied For the Short-Term. Wait for the Spoc
                        Approval.
                      </Typography>
                    ) : (
                      "Are You Applying for Short-Term Certificate?"
                    )}
                  </FormLabel>
                  {!shortTermApplied &&
                    isMainStatus !== 4 &&
                    isMainStatus !== 3 && (
                      <RadioGroup
                        row
                        value={shortTerm}
                        onChange={handleShortTermChange}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                </FormControl>
              </Grid>
            </Grid>
          </form>
        );
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <LoadingOverlay>
          <CircularProgress
            size={60}
            thickness={4}
            style={{ color: "white" }}
          />
        </LoadingOverlay>
      );
    }

    if (Array.isArray(coursesDetails) && coursesDetails.length === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Typography variant="h5" color="error">
            No certificate found
          </Typography>
        </Box>
      );
    }

    if (longTermApplied && shortTermApplied) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: 2, md: 4 },
            height: "100vh",
            p: { xs: 2, md: 0 }, // Padding adjustment for smaller screens
          }}
        >
          <Card
            sx={{
              p: { xs: 3, md: 5 },
              maxWidth: { xs: "100%", md: 600 },
              width: "100%",
              textAlign: "center",
              boxShadow: "none",
              backgroundColor: "#f9f9f9",
              borderRadius: 3,
            }}
          >
            <CheckCircleOutlineIcon
              sx={{
                fontSize: { xs: 40, md: 60 },
                color: "#2b7bfc",
                mb: { xs: 2, md: 3 },
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#333",
                fontSize: { xs: "1.2rem", md: "1.5rem" },
              }}
            >
              {/* {isApscheStatus === 2 &&
                isMainStatus !== 2 &&
                "Your Long-Term Certificate"}
              {isMainStatus === 2 &&
                isApscheStatus !== 2 &&
                "Your Short-Term Certificate"}
              {isApscheStatus === 2 &&
                isMainStatus === 2 &&
                "Your Both Certificates"} */}
              Form submitted successfully. Wait for the Spoc approval.
            </Typography>
          </Card>

          {(isApscheStatus === 3 || isMainStatus === 3) && (
            <Grid
              container
              spacing={2}
              sx={{
                mt: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              {isMainStatus === 3 && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Your Short-Term certificate generated
                  </Typography>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    startIcon={<ShortTextIcon />}
                    onClick={() =>
                      navigate("/internship/certificate/short-term")
                    }
                    size="small"
                    fullWidth
                  >
                    Short-Term Certificate
                  </StyledButton>
                </Grid>
              )}
              {isApscheStatus === 3 && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Your Long-Term Certificate generated
                  </Typography>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    startIcon={<AssignmentIcon />}
                    onClick={() =>
                      navigate("/internship/certificate/long-term")
                    }
                    size="small"
                    fullWidth
                  >
                    Long-Term Certificate
                  </StyledButton>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      );
    }

    if (isApscheStatus === 3 && isMainStatus === 3) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Choose Your Certificate Type
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <StyledButton
                variant="contained"
                color="primary"
                startIcon={<AssignmentIcon />}
                onClick={() => navigate("/internship/certificate/long-term")}
                size="large"
              >
                Long-Term Certificate
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="contained"
                color="warning"
                startIcon={<ShortTextIcon />}
                onClick={() => navigate("/internship/certificate/short-term")}
                size="large"
              >
                Short-Term Certificate
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      );
    }

    if (isApscheStatus === 4 && isMainStatus !== 4) {
      return (
        <>
          {(isApscheStatus === 3 || isMainStatus === 3) && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{
                mt: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              {isMainStatus === 3 && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Your Short-Term certificate generated
                  </Typography>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    startIcon={<ShortTextIcon />}
                    onClick={() =>
                      navigate("/internship/certificate/short-term")
                    }
                    size="small"
                    fullWidth
                  >
                    Short-Term Certificate
                  </StyledButton>
                </Grid>
              )}
              {isApscheStatus === 3 && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Your Long-Term Certificate generated
                  </Typography>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    startIcon={<AssignmentIcon />}
                    onClick={() =>
                      navigate("/internship/certificate/long-term")
                    }
                    size="small"
                    fullWidth
                  >
                    Long-Term Certificate
                  </StyledButton>
                </Grid>
              )}
            </Grid>
          )}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 4, width: "100%" }}
          >
            <Card
              sx={{
                p: { xs: 2, sm: 3 },
                m: { xs: 1, sm: 2 },
                maxWidth: 600,
                width: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: { xs: "auto", sm: "200px" },
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Adds a soft shadow
              }}
            >
              <CancelOutlinedIcon
                sx={{
                  fontSize: { xs: 50, sm: 80 },
                  color: "error.main",
                  mb: { xs: 1, sm: 2 },
                }}
              />
              <Typography
                variant="h6"
                color="error.main"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                  textAlign: "center",
                }}
              >
                Your{" "}
                {isApscheStatus === 4 &&
                  isMainStatus !== 4 &&
                  "Long-Term Certificate"}{" "}
                {/* {isMainStatus === 4 &&
            isApscheStatus !== 4 &&
            "Short-Term Certificate"}
          {isApscheStatus === 4 && isMainStatus === 4 && "Both Certificates "}{" "} */}
                form has been rejected. Please contact your SPOC.
              </Typography>
            </Card>
          </Grid>
        </>
      );
    }

    if (isApscheStatus !== 4 && isMainStatus === 4) {
      return (
        <>
          {(isApscheStatus === 3 || isMainStatus === 3) && (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{
                mt: 2,
                width: "100%",
                textAlign: "center",
              }}
            >
              {isMainStatus === 3 && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Your Short-Term certificate generated
                  </Typography>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    startIcon={<ShortTextIcon />}
                    onClick={() =>
                      navigate("/internship/certificate/short-term")
                    }
                    size="small"
                    fullWidth
                  >
                    Short-Term Certificate
                  </StyledButton>
                </Grid>
              )}
              {isApscheStatus === 3 && (
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Your Long-Term Certificate generated
                  </Typography>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    startIcon={<AssignmentIcon />}
                    onClick={() =>
                      navigate("/internship/certificate/long-term")
                    }
                    size="small"
                    fullWidth
                  >
                    Long-Term Certificate
                  </StyledButton>
                </Grid>
              )}
            </Grid>
          )}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 4, width: "100%" }}
          >
            <Card
              sx={{
                p: { xs: 2, sm: 3 },
                m: { xs: 1, sm: 2 },
                maxWidth: 600,
                width: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: { xs: "auto", sm: "200px" },
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Adds a soft shadow
              }}
            >
              <CancelOutlinedIcon
                sx={{
                  fontSize: { xs: 50, sm: 80 },
                  color: "error.main",
                  mb: { xs: 1, sm: 2 },
                }}
              />
              <Typography
                variant="h6"
                color="error.main"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                  textAlign: "center",
                }}
              >
                Your{" "}
                {/* {isApscheStatus === 4 &&
                  isMainStatus !== 4 &&
                  "Long-Term Certificate"} */}
                {isMainStatus === 4 &&
                  isApscheStatus !== 4 &&
                  "Short-Term Certificate"}{" "}
                {/* {isApscheStatus === 4 && isMainStatus === 4 && "Both Certificates "}{" "} */}
                form has been rejected. Please contact your SPOC.
              </Typography>
            </Card>
          </Grid>
        </>
      );
    }

    // if (isApscheStatus === 3 || isMainStatus === 3) {
    //   return (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: "column",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         height: "100vh",
    //       }}
    //     >
    //       <Card
    //         sx={{
    //           p: 5,
    //           maxWidth: 600,
    //           width: "100%",
    //           textAlign: "center",
    //           boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    //           mb: 4,
    //         }}
    //       >
    //         <Typography variant="h5" gutterBottom>
    //           APSCHE Certificate
    //         </Typography>
    //         {isApscheStatus === 3 && (
    //           <>
    //             <Typography variant="body1" sx={{ mb: 2 }}>
    //               Your Short-Term certificate generated
    //             </Typography>
    //             <StyledButton
    //               variant="contained"
    //               color="primary"
    //               startIcon={<ShortTextIcon />}
    //               onClick={() => navigate("/internship/certificate/short-term")}
    //               size="large"
    //             >
    //               Short-Term Certificate
    //             </StyledButton>
    //           </>
    //         )}
    //         {isMainStatus === 3 && (
    //           <>
    //             <Typography variant="body1" sx={{ mb: 2 }}>
    //               Your Long-Term Certificate generated
    //             </Typography>
    //             <StyledButton
    //               variant="contained"
    //               color="primary"
    //               startIcon={<AssignmentIcon />}
    //               onClick={() => navigate("/internship/certificate/long-term")}
    //               size="large"
    //             >
    //               Long-Term Certificate
    //             </StyledButton>
    //           </>
    //         )}
    //       </Card>
    //     </Box>
    //   );
    // }
    switch (isApscheStatus && isMainStatus) {
      case 2:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: { xs: 2, md: 4 },
              height: "100vh",
              p: { xs: 2, md: 0 }, // Padding adjustment for smaller screens
            }}
          >
            <Card
              sx={{
                p: { xs: 3, md: 5 },
                maxWidth: { xs: "100%", md: 600 },
                width: "100%",
                textAlign: "center",
                boxShadow: "none",
                backgroundColor: "#f9f9f9",
                borderRadius: 3,
              }}
            >
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: { xs: 40, md: 60 },
                  color: "#2b7bfc",
                  mb: { xs: 2, md: 3 },
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "#333",
                  fontSize: { xs: "1.2rem", md: "1.5rem" },
                }}
              >
                {isApscheStatus === 2 &&
                  isMainStatus !== 2 &&
                  "Your Long-Term Certificate"}
                {isMainStatus === 2 &&
                  isApscheStatus !== 2 &&
                  "Your Short-Term Certificate"}
                {isApscheStatus === 2 &&
                  isMainStatus === 2 &&
                  "Your Both Certificates "}{" "}
                Form submitted successfully. Waiting for the approval.
              </Typography>
            </Card>

            {(isApscheStatus === 3 || isMainStatus === 3) && (
              <Grid
                container
                spacing={2}
                sx={{
                  mt: 2,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {isMainStatus === 3 && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      Your Short-Term certificate generated
                    </Typography>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      startIcon={<ShortTextIcon />}
                      onClick={() =>
                        navigate("/internship/certificate/short-term")
                      }
                      size="small"
                      fullWidth
                    >
                      Short-Term Certificate
                    </StyledButton>
                  </Grid>
                )}
                {isApscheStatus === 3 && (
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      Your Long-Term Certificate generated
                    </Typography>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      startIcon={<AssignmentIcon />}
                      onClick={() =>
                        navigate("/internship/certificate/long-term")
                      }
                      size="small"
                      fullWidth
                    >
                      Long-Term Certificate
                    </StyledButton>
                  </Grid>
                )}
              </Grid>
            )}
          </Box>
        );

      case 4:
        return (
          <>
            <Card
              sx={{
                p: { xs: 2, sm: 3 },
                m: { xs: 1, sm: 2 },
                maxWidth: 600,
                width: "100%",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: { xs: "auto", sm: "200px" },
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Adds a soft shadow
              }}
            >
              <CancelOutlinedIcon
                sx={{
                  fontSize: { xs: 50, sm: 80 },
                  color: "error.main",
                  mb: { xs: 1, sm: 2 },
                }}
              />
              <Typography
                variant="h6"
                color="error.main"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.25rem" },
                  textAlign: "center",
                }}
              >
                Your{" "}
                {isApscheStatus === 4 &&
                  isMainStatus !== 4 &&
                  "Long-Term Certificate"}
                {isMainStatus === 4 &&
                  isApscheStatus !== 4 &&
                  "Short-Term Certificate"}
                {isApscheStatus === 4 &&
                  isMainStatus === 4 &&
                  "Both Certificates "}{" "}
                form has been rejected. Please contact your SPOC.
              </Typography>
            </Card>
            {(isApscheStatus === 3 || isMainStatus === 3) && (
              <Box
                sx={{
                  mt: 2,
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {isMainStatus === 3 && (
                  <Box sx={{ mb: 2, width: "100%", px: { xs: 1, sm: 0 } }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      Your Short-Term certificate generated
                    </Typography>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      startIcon={<ShortTextIcon />}
                      onClick={() =>
                        navigate("/internship/certificate/short-term")
                      }
                      size="small"
                      fullWidth
                    >
                      Short-Term Certificate
                    </StyledButton>
                  </Box>
                )}
                {isApscheStatus === 3 && (
                  <Box
                    sx={{
                      mb: 2,
                      width: "100%",
                      px: { xs: 1, sm: 0 },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ mb: 1, textAlign: "center" }}
                    >
                      Your Long-Term Certificate generated
                    </Typography>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      startIcon={<AssignmentIcon />}
                      onClick={() =>
                        navigate("/internship/certificate/long-term")
                      }
                      size="small"
                      fullWidth
                    >
                      Long-Term Certificate
                    </StyledButton>
                  </Box>
                )}
              </Box>
            )}
          </>
        );
      case 1:
      default:
        return (
          <div className="flex flex-col items-center min-h-screen bg-gray-100">
            <Grid container marginTop={6} spacing={3} justifyContent="center">
              {certificateTypes.map((cert) => (
                <Grid item key={cert.type}>
                  <CertificateCard onClick={() => handleOpenDialog(cert.type)}>
                    <img
                      src={cert.icon}
                      alt={cert.label}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        padding: "8px",
                        color: "white",
                      }}
                    >
                      <Typography variant="h6" align="center">
                        {cert.label}
                      </Typography>
                      <Typography variant="body2" align="center">
                        Click to apply
                      </Typography>
                    </div>
                  </CertificateCard>

                  {(isApscheStatus === 3 || isMainStatus === 3) && (
                    <Box sx={{ mt: 2, width: "100%", textAlign: "center" }}>
                      {isMainStatus === 3 && (
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="body1" sx={{ mb: 1 }}>
                            Your Short-Term certificate generated
                          </Typography>
                          <StyledButton
                            variant="contained"
                            color="primary"
                            startIcon={<ShortTextIcon />}
                            onClick={() =>
                              navigate("/internship/certificate/short-term")
                            }
                            size="small"
                            fullWidth
                          >
                            Short-Term Certificate
                          </StyledButton>
                        </Box>
                      )}
                      {isApscheStatus === 3 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            mr: 15,
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{ mb: 1, textAlign: "center" }}
                          >
                            Your Long-Term Certificate generated
                          </Typography>
                          <StyledButton
                            variant="contained"
                            color="primary"
                            startIcon={<AssignmentIcon />}
                            onClick={() =>
                              navigate("/internship/certificate/long-term")
                            }
                            size="small"
                            fullWidth
                          >
                            Long-Term Certificate
                          </StyledButton>
                        </Box>
                      )}
                    </Box>
                  )}
                </Grid>
              ))}
            </Grid>
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col  items-center min-h-screen bg-gray-100">
      {renderContent()}

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle style={{ backgroundColor: blue[700], color: "white" }}>
          {selectedCertificate
            ? `${
                selectedCertificate.charAt(0).toUpperCase() +
                selectedCertificate.slice(1)
              } Certificate`
            : "Certificate"}
        </DialogTitle>
        <DialogContent>
          <StyledCard id="certificate-content">{renderForm()}</StyledCard>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained" color="error">
            Close
          </Button>
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            // disabled={
            //   isApscheStatus === 4 ||
            //   isMainStatus === 4 ||
            //   isApscheStatus === 3 ||
            //   isMainStatus === 3
            // }
          >
            Submit
          </StyledButton>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={showEligibilityDialog}
        onClose={handleCloseEligibilityDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Certificate Application Eligibility</DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: "1.2rem", // Increase the font size
              color: red[500], // Set the color to Material UI's red
            }}
          >
            {isLongTermApplied === 0
              ? "You are  eligible for a Short-Term certificate. you can apply for it; if applied, ignore this message."
              : "You are eligible for both long-term and short-term certificates. You can apply for either or both; if applied, ignore this message."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEligibilityDialog}
            variant="contained"
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog> */}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Certificate;

// case "course":
//   return (
//     <form className="space-y-6" onSubmit={handleSubmit}>
//       <div className="flex items-center space-x-4">
//         <StyledTextField
//           label="Course Name"
//           variant="outlined"
//           fullWidth
//         />
//       </div>
//       {/* Add more fields for course completion certificate */}
//     </form>
//   );
// case "achievement":
//   return (
//     <form className="space-y-6" onSubmit={handleSubmit}>
//       <div className="flex items-center space-x-4">
//         <StyledTextField
//           label="Achievement Title"
//           variant="outlined"
//           fullWidth
//         />
//       </div>
//       {/* Add more fields for achievement certificate */}
//     </form>
//   );
// case "participation":
//   return (
//     <form className="space-y-6" onSubmit={handleSubmit}>
//       <div className="flex items-center space-x-4">
//         <StyledTextField
//           label="Event Name"
//           variant="outlined"
//           fullWidth
//         />
//       </div>
//       {/* Add more fields for participation certificate */}
//     </form>
//   );
// case "completion":
//   return (
//     <form className="space-y-6" onSubmit={handleSubmit}>
//       <div className="flex items-center space-x-4">
//         <StyledTextField
//           label="Program Name"
//           variant="outlined"
//           fullWidth
//         />
//       </div>
//       {/* Add more fields for program completion certificate */}
//     </form>
//   );
