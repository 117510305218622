// authoriseSlice.js

import { createSlice } from "@reduxjs/toolkit";

const authoriseSlice = createSlice({
  name: "authorise",
  initialState: {
    userRole: localStorage.getItem("Authorise"),
  },
  reducers: {
    clearAuthorise: (state) => {
      // Reset the state to its initial values
      state.userRole = "";
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
  },
});

export const { setUserRole, clearAuthorise } = authoriseSlice.actions;

export default authoriseSlice.reducer;
