import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const ProjectDetailsForm = ({
  onNext,
  handleBack,
  onError,
  onComplete,
  initialData,
}) => {
  const [projectData, setProjectData] = useState([]);
  const [errors, setErrors] = useState([]);

  const errorMessages = {
    projectTitleRequired: "Project Title is required",
    projectDescriptionRequired: "Project Description is required",
    projectDescriptionWordLimit:
      "Project Description should not exceed 50 words",
  };

  useEffect(() => {
    // console.log("initialData:", initialData);
    const storedData = localStorage.getItem("projectData");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        // console.log("Setting projectData from localStorage:", parsedData);
        setProjectData(parsedData);
      } catch (error) {
        console.error("Failed to parse stored project data:", error);
      }
    } else {
      // console.log(
      //   "Setting projectData from initialData:",
      //   initialData?.projects
      // );
      if (
        initialData &&
        initialData.projects &&
        initialData.projects.length > 0
      ) {
        setProjectData(initialData.projects);
      } else {
        setProjectData([{ projectTitle: "", projectDescription: "" }]);
      }
    }
  }, [initialData]);

  useEffect(() => {
    localStorage.setItem("projectData", JSON.stringify(projectData));
  }, [projectData]);

  const handleInputChange = (index, field, value) => {
    setProjectData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [field]: value };
      return newData;
    });

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = { ...newErrors[index], [field]: false };
      return newErrors;
    });
  };

  const handleValidate = () => {
    let isValid = true;

    const newErrors = projectData.map((project) => {
      const errors = {
        projectTitle: !project.projectTitle,
        projectDescriptionRequired: !project.projectDescription,
        projectDescriptionWordLimit:
          project.projectDescription.trim().split(/\s+/).length > 50,
      };

      if (Object.values(errors).some((error) => error)) {
        isValid = false;
      }

      return errors;
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleAddProject = () => {
    if (handleValidate()) {
      const newProject = {
        projectTitle: "",
        projectDescription: "",
      };

      setProjectData((prevData) => [...prevData, newProject]);
      setErrors((prevErrors) => [
        ...prevErrors,
        {
          projectTitle: false,
          projectDescription: false,
        },
      ]);
    }
  };

  const handleDeleteProject = (index) => {
    setProjectData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      return newData;
    });

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors.splice(index, 1);
      return newErrors;
    });
  };

  const handleNext = () => {
    if (!projectData || projectData.length === 0) {
      alert("Please add at least one project.");
      return;
    }

    if (handleValidate()) {
      // Ensure onNext is called with the correct data format
      if (typeof onNext === "function") {
        onNext([...projectData]); // Spread the array to ensure a new array reference
      }
      if (typeof onError === "function") {
        onError(false);
      }
      if (typeof onComplete === "function") {
        onComplete();
      }
    } else {
      if (typeof onError === "function") {
        onError(true);
      }
    }
  };

  return (
    <div className="relative min-h-[620px] max-h-auto">
      <div className="p-2 md:p-2 lg:p-4">
        <h2 className="text-lg text-center font-semibold text-slate-400">
          Add your project
        </h2>
        {projectData.map((project, index) => (
          <div
            key={index}
            className="grid grid-cols-1 md:grid-cols-1 gap-4 border-2 rounded-md p-3 border-gray-200 my-10"
          >
            <div className="mb-4">
              <label
                htmlFor={`projectTitle_${index}`}
                className="block text-gray-700 text-sm font-bold md:mr-4"
              >
                Project Title
              </label>
              <input
                type="text"
                id={`projectTitle_${index}`}
                placeholder="Project Title"
                value={project.projectTitle}
                onChange={(e) =>
                  handleInputChange(index, "projectTitle", e.target.value)
                }
                required
                className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                  errors[index]?.projectTitle ? "border-red-500" : ""
                }`}
              />
              {errors[index]?.projectTitle && (
                <p className="text-red-500 text-sm">
                  {errorMessages.projectTitleRequired}
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor={`projectDescription_${index}`}
                className="block text-gray-700 text-sm font-bold md:mr-4"
              >
                Project Description
              </label>
              <textarea
                id={`projectDescription_${index}`}
                placeholder="Project Description within 50 words"
                value={project.projectDescription}
                onChange={(e) =>
                  handleInputChange(index, "projectDescription", e.target.value)
                }
                required
                className={`border-2 text-gray-950 mt-1 pl-1 h-20 md:h-32 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                  errors[index]?.projectDescription ? "border-red-500" : ""
                }`}
              />
              {errors[index]?.projectDescriptionRequired && (
                <p className="text-red-500 text-sm">
                  {errorMessages.projectDescriptionRequired}
                </p>
              )}
              {errors[index]?.projectDescriptionWordLimit && (
                <p className="text-red-500 text-sm">
                  {errorMessages.projectDescriptionWordLimit}
                </p>
              )}
            </div>
            <div className="flex justify-between items-center">
              {projectData.length > 1 && (
                <button
                  onClick={() => handleDeleteProject(index)}
                  className="py-1 px-1 flex rounded-md border-2 border-red-300 gap-1 text-red-400 hover:bg-red-400 hover:text-white"
                >
                  <Icon
                    icon="bx:bx-trash"
                    className="font-bold"
                    height={20}
                    width={20}
                  />
                  <h2>Delete</h2>
                </button>
              )}
            </div>
          </div>
        ))}

        <div className="flex justify-end mb-5">
          <button
            onClick={handleAddProject}
            className="py-1 px-1 flex rounded-md border-2 border-green-300 gap-1 text-green-400 hover:bg-green-400 hover:text-white"
          >
            <Icon
              icon="material-symbols:add"
              className="font-bold"
              height={20}
              width={20}
            />
            <h2>Add more</h2>
          </button>
        </div>
      </div>
      <div className="left-0 w-full mt-20 flex justify-between">
        <div className="ml-4 flex justify-start md:absolute md:bottom-2 mb-2">
          <Button
            className="mt-4"
            variant="contained"
            color="success"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        <div className="flex justify-end md:absolute md:bottom-2 mb-2 mr-2 md:right-2">
          <Button
            className="mt-4 mb-4"
            variant="contained"
            color="secondary"
            onClick={handleNext}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsForm;
